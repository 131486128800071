import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faInfoCircle,
  faLeaf,
  faMapMarkerAlt,
  faDollarSign,
  faHeart,
  faSpinner,
  faSearch,
  faArrowLeft,
  faArrowRight,
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import logo from "./icons/keboon-logo-white.png";
import { Helmet } from "react-helmet";
import FundraiseTab from "./FundraiseTab";

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.$active ? "#377e62" : "white")};
  color: ${(props) => (props.$active ? "white" : "#377e62")};
  border: 2px solid #377e62;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &:hover {
    background-color: ${(props) => (props.$active ? "#2c6550" : "#f0f0f0")};
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftNavContainer = styled.div`
  position: absolute;
  left: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const LeftNavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
`;

const NavLinks = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  gap: 20px;
`;

const SaveKeboonButton = styled.button`
  padding: 10px 20px;
  background-color: #377e62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;

  &:hover {
    background-color: #2c6550;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ShareKeboonButton = styled(SaveKeboonButton)`
  ${(props) =>
    props.$withSaveButton ? "margin-left: 10px;" : "margin: 10px auto;"}
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: ${(props) => (props.isLoggedIn ? "60px" : "0")};
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #377e62;
  margin-bottom: 30px;
`;

const KeboonInfoSection = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PhotoCarousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const CarouselPhoto = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
`;

const CarouselButton = styled.button`
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  margin: 0 10px;

  &:hover {
    background-color: #377e62;
  }
`;

const KeboonIntro = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  white-space: pre-wrap;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #377e62;
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #377e62;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.p`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52ae77;
`;

const LocationLink = styled.p`
  margin: 0 0 5px 0;
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const InterestedButton = styled.button`
  padding: 5px 10px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377e62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalInput = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.active ? "#377E62" : "white")};
  color: ${(props) => (props.active ? "white" : "#333")};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377e62;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #ff6b6b;
  margin-top: 50px;
`;

const TotalPriceDisplay = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #377e62;
  margin-top: 10px;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const PriceComparisonText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  color: ${(props) =>
    props.status === "above"
      ? "#FF5722" // Red for above market price
      : props.status === "within"
      ? "#4CAF50" // Green for within market price
      : "#4CAF50"}; // Green for below market price
`;

const MarketPriceInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 2px;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  width: max-content;
  max-width: 200px;
  display: none;
`;

const PriceInfoIcon = styled.span`
  position: relative;
  margin-left: 5px;
  cursor: help;

  &:hover ${Tooltip} {
    display: block;
  }
`;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const productIcon = new L.Icon({
  iconUrl: "https://keboon.net/assets/img/icon-outline.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const userIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const unitNormalization = {
  kg: [
    /\b(?:\d*\.?\d*\s*)?(?:kg|kgs|kilo|kilos|kilogram|kilograms|Kg|KG)\b/i,
    /±?\s*\d*\.?\d*\s*kg\/seekor/i,
  ],
  "100g": [
    /\b(?:\d*\.?\d*\s*)?(?:g|gram|grams|G)\b/i,
    /(?:±|\+\-)\s*\d*\.?\d*\s*g/i,
  ],
  L: [
    /\b(?:\d*\.?\d*\s*)?(?:l|L|liter|liters|litre|litres)\b/i,
    /\b\d*\.?\d*\s*(?:l|L|liter|liters|litre|litres)\b/i,
  ],
  mL: [
    /\b(?:\d*\.?\d*\s*)?(?:ml|mL|milliliter|milliliters|millilitre|millilitres)\b/i,
  ],
  "10 pieces": [
    /\b(?:\d*\.?\d*\s*)?(?:10 biji|10 pieces|10 pcs|sepuluh biji)\b/i,
  ],
  piece: [
    /\b(?:\d*\.?\d*\s*)?(?:piece|pieces|pc|pcs|unit|units|biji|bijis|set|sets|sheet|sheets|ekor|ekors)\b/i,
    /\b(?:se|1)(?:biji|unit|set|naskah|pinggan|bungkus|mangkuk|ketul|piring|keping|gelas|batang|paket)\b/i,
  ],
};

const baseConversions = {
  kg: {
    base: 1000,
    "100g": 10,
  },
  "100g": {
    base: 100,
    kg: 0.1,
  },
  L: {
    base: 1000,
    mL: 1000,
  },
  mL: {
    base: 1,
    L: 0.001,
  },
  "10 pieces": {
    base: 10,
    piece: 0.1,
  },
  piece: {
    base: 1,
    "10 pieces": 10,
  },
};

const extractNumericValue = (unitStr) => {
  if (!unitStr) return 1;
  const cleanStr = unitStr.replace(/[±\+\-]/g, "").trim();
  const matches = cleanStr.match(/(\d*\.?\d+)/);
  return matches ? parseFloat(matches[1]) : 1;
};

const normalizeUnit = (unit) => {
  if (!unit) return null;
  const cleanUnit = unit.trim().toLowerCase();
  for (const [normalizedUnit, patterns] of Object.entries(unitNormalization)) {
    for (const pattern of patterns) {
      if (pattern.test(cleanUnit)) {
        return normalizedUnit;
      }
    }
  }
  return unit;
};

const stripNumericPrefix = (unit) => {
  return unit.replace(/^\d+\s*/, "").trim();
};

function SharedKeboonPage() {
  const [showWhatsAppPopup, setShowWhatsAppPopup] = useState(false);
  const { shareId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [interestedProductId, setInterestedProductId] = useState(null);
  const [isInterestLoading, setIsInterestLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [mapCenter, setMapCenter] = useState([3.139003, 101.686855]); // Default center (Kuala Lumpur)
  const [userLocation, setUserLocation] = useState(null);
  const productsPerPage = 5;
  const [keboonPhotos, setKeboonPhotos] = useState([]);
  const [keboonIntro, setKeboonIntro] = useState("");
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [quantity, setQuantity] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const [isSavingKeboon, setIsSavingKeboon] = useState(false);
  const [isDirectAccess, setIsDirectAccess] = useState(true);
  const [activeTab, setActiveTab] = useState("keboon");

  useEffect(() => {
    // Get navigation state from history
    const navigationState = window.history.state;
    const hasNavigationState =
      navigationState && navigationState.usr !== undefined;

    // Check if coming from main app routes
    const referrer = document.referrer;
    const currentUrl = window.location.origin;
    const isInternalNavigation = referrer.startsWith(currentUrl);
    const isFromMainApp =
      referrer.includes("/main") ||
      referrer.includes("/my-listing") ||
      referrer.includes("/profile");

    // Consider it direct access if:
    // 1. User is not logged in OR
    // 2. No navigation state exists AND not coming from main app routes
    setIsDirectAccess(
      !isLoggedIn ||
        (!hasNavigationState && !isFromMainApp && !isInternalNavigation)
    );

    // Log for debugging
    console.log({
      navigationState,
      hasNavigationState,
      referrer,
      currentUrl,
      isInternalNavigation,
      isFromMainApp,
      isLoggedIn,
      isDirectAccess:
        !isLoggedIn ||
        (!hasNavigationState && !isFromMainApp && !isInternalNavigation),
    });
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);

    if (token) {
      fetchUserProfile(token);
    }

    const fetchSharedProducts = async () => {
      try {
        const timestamp = new Date().getTime();
        const [sharedResponse, pricesResponse] = await Promise.all([
          axios.get(`/api/shared-keboon/${shareId}?t=${timestamp}`),
          axios.get(
            "https://api-dot-keboon-ai-439616.as.r.appspot.com/api/average-prices"
          ),
        ]);

        console.log("API Response:", sharedResponse.data);

        const { products, userName, keboonPhotos, keboonIntro } =
          sharedResponse.data;
        const priceAnalysisData = pricesResponse.data;

        const getConvertedPrice = (price, fromUnit, toUnit) => {
          const normalized = {
            from: normalizeUnit(fromUnit),
            to: normalizeUnit(toUnit),
          };

          // Handle piece to 10 pieces conversion for eggs
          if (normalized.from === "piece" && normalized.to === "10 pieces") {
            return price * 10; // 0.8/piece -> 8/10pieces
          }

          // Handle 10 pieces to piece conversion
          if (normalized.from === "10 pieces" && normalized.to === "piece") {
            return price / 10; // 8/10pieces -> 0.8/piece
          }

          // Handle 100g to kg conversion
          if (normalized.from === "100g" && normalized.to === "kg") {
            return price * 10; // 0.7/100g -> 7/kg
          }

          // Handle kg to 100g conversion
          if (normalized.from === "kg" && normalized.to === "100g") {
            return price / 10; // 7/kg -> 0.7/100g
          }

          // Handle L to mL conversion
          if (normalized.from === "L" && normalized.to === "mL") {
            return price * 1000; // 0.7/L -> 700/mL
          }

          // Handle mL to L conversion
          if (normalized.from === "mL" && normalized.to === "L") {
            return price / 1000; // 700/mL -> 0.7/L
          }

          // Same units or unsupported conversion
          return price;
        };

        const productsWithAnalysis = products.map((product) => {
          // If product has priceReference, use it without additional analysis
          if (product.priceReference) {
            return {
              ...product,
              priceAnalysis: {
                status: product.priceReference.status,
                meanPrice: product.priceReference.meanPrice,
                minPrice: product.priceReference.min,
                maxPrice: product.priceReference.max,
                referenceUnit: product.priceReference.unit,
                convertedPrice: product.priceReference.convertedPrice,
                matched: true,
              },
            };
          }

          // If product explicitly has null priceReference, don't perform analysis
          if (
            product.hasOwnProperty("priceReference") &&
            product.priceReference === null
          ) {
            return {
              ...product,
              priceAnalysis: null,
            };
          }

          let analysis = Object.entries(priceAnalysisData).find(
            ([name, data]) => name.toLowerCase() === product.name.toLowerCase()
          );

          if (!analysis) {
            analysis = Object.entries(priceAnalysisData).find(
              ([name, data]) =>
                product.name.toLowerCase().includes(name.toLowerCase()) ||
                name.toLowerCase().includes(product.name.toLowerCase())
            );
          }

          if (analysis) {
            const [, priceData] = analysis;

            // Convert product price to reference unit for comparison
            const convertedPrice = getConvertedPrice(
              product.price,
              product.unit,
              priceData.unit
            );

            // Calculate the percentage difference from mean price
            const percentageDiff = Math.abs(
              (convertedPrice - priceData.mean) / priceData.mean
            );

            // Price is considered "within" market price if it's within 20% of the mean
            const status =
              percentageDiff <= 0.2
                ? "within"
                : convertedPrice < priceData.mean
                ? "below"
                : "above";

            return {
              ...product,
              priceAnalysis: {
                status,
                meanPrice: priceData.mean,
                minPrice: priceData.min,
                maxPrice: priceData.max,
                referenceUnit: priceData.unit,
                convertedPrice,
                matched: true,
              },
            };
          }

          return {
            ...product,
            priceAnalysis: null,
          };
        });

        const sortedProducts = productsWithAnalysis.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        setProducts(sortedProducts);
        setUserName(userName || "Keboon User");
        setKeboonPhotos(keboonPhotos || []);
        setKeboonIntro(keboonIntro || "");

        if (sortedProducts.length > 0 && sortedProducts[0].location) {
          setMapCenter([
            sortedProducts[0].location.lat,
            sortedProducts[0].location.lon,
          ]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching shared Keboon products:", error);
        setError(
          error.response?.data?.message || "Failed to load shared products"
        );
        setLoading(false);
      }
    };

    fetchSharedProducts();
  }, [shareId]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(userLoc);
          setMapCenter([userLoc.latitude, userLoc.longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchUserProfile = useCallback(async (token) => {
    try {
      const response = await axios.get("/api/user/profile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserProfile(response.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const renderIntroduction = (text) => {
    return text.split("\n").map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph}
        {index < text.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleInterested = (product) => {
    setInterestedProductId(product._id);
    setSelectedProduct(product); // product already contains sellerPhone from the API
    if (isLoggedIn && userProfile) {
      setName(userProfile.name);
      setMobileNumber(userProfile.mobileNumber);
    } else {
      setName("");
      setMobileNumber("");
    }
    setQuantity("");
    setTotalPrice(0);
    setShowModal(true);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);

    if (selectedProduct && newQuantity) {
      // Round to 2 decimal places for consistent precision
      const calculatedTotal = (
        selectedProduct.price * parseFloat(newQuantity)
      ).toFixed(2);
      setTotalPrice(!isNaN(calculatedTotal) ? parseFloat(calculatedTotal) : 0);
    }
  };

  const handleSubmitInterest = async () => {
    if (!name.trim() || !mobileNumber.trim() || !quantity.trim()) {
      alert("Please fill in all fields.");
      return;
    }

    const mobileRegex =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!mobileRegex.test(mobileNumber)) {
      alert("Please enter a valid mobile number.");
      return;
    }

    const quantityNum = parseInt(quantity);
    if (isNaN(quantityNum) || quantityNum <= 0) {
      alert("Please enter a valid quantity.");
      return;
    }

    setIsInterestLoading(true);
    try {
      const payload = {
        name: name.trim(),
        mobileNumber: mobileNumber.trim(),
        quantity: quantityNum,
        totalPrice: totalPrice,
      };

      const response = await axios.post(
        `/api/products/${interestedProductId}/interest`,
        payload,
        isLoggedIn
          ? {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          : {}
      );

      alert(
        response.data.message ||
          "You have expressed interest in this product. The seller will be notified."
      );

      // If seller's phone number is available, show WhatsApp confirmation popup
      if (response.data.sellerPhone) {
        setSelectedProduct((prev) => ({
          ...prev,
          sellerPhone: response.data.sellerPhone,
        }));
        setShowWhatsAppPopup(true);
      } else {
        setShowModal(false);
        // Only reset form if not showing WhatsApp popup
        setQuantity("");
        setTotalPrice(0);
      }
    } catch (error) {
      console.error("Error expressing interest:", error);
      alert(
        error.response?.data?.message ||
          "Failed to express interest. Please try again."
      );
    } finally {
      setIsInterestLoading(false);
    }
  };

  const handleNextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % keboonPhotos.length);
  };

  const handlePrevPhoto = () => {
    setCurrentPhotoIndex(
      (prevIndex) => (prevIndex - 1 + keboonPhotos.length) % keboonPhotos.length
    );
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const filteredProducts = products
    .filter((product) =>
      product.name.toLowerCase().includes(search.toLowerCase())
    )
    .map((product) => ({
      ...product,
      distance:
        userLocation && product.location
          ? calculateDistance(
              userLocation.latitude,
              userLocation.longitude,
              product.location.lat,
              product.location.lon
            )
          : Infinity,
    }));

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach((product) => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  const handleImageError = (index) => {
    console.error(`Error loading image at index ${index}`);
  };

  const handleSignIn = () => {
    navigate(`/login?redirect=/shared-keboon/${shareId}`);
  };

  const handleSignUp = () => {
    navigate(`/signup?redirect=/shared-keboon/${shareId}`);
  };

  if (loading) {
    return (
      <PageWrapper>
        <LoadingMessage>Loading shared products...</LoadingMessage>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <ErrorMessage>{error}</ErrorMessage>
      </PageWrapper>
    );
  }

  const handleSaveKeboon = async () => {
    if (!isLoggedIn) {
      return;
    }

    setIsSavingKeboon(true);
    try {
      const keboonLink = window.location.href;
      await axios.post(
        "/api/user/saved-keboons",
        { keboonLink },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      alert("Keboon saved successfully!");
    } catch (error) {
      console.error("Error saving Keboon:", error);
      alert(
        error.response?.data?.message ||
          "Failed to save Keboon. Please try again."
      );
    } finally {
      setIsSavingKeboon(false);
    }
  };

  return (
    <PageWrapper isLoggedIn={isLoggedIn && !isDirectAccess}>
      <Helmet>
        <title>Check out {userName}'s Keboon!</title>
        {/* Generate product list for description */}
        {(() => {
          const productNames = products
            .map((product) => product.name)
            .filter((name, index, self) => self.indexOf(name) === index) // Remove duplicates
            .slice(0, 5); // Take first 5 products
          const description =
            productNames.length > 0
              ? `Available: ${productNames.join(", ")}${
                  productNames.length < products.length ? " and more!" : ""
                }`
              : "Check out my fresh local produce!";

          return (
            <>
              <meta name="description" content={description} />
              {/* Open Graph metadata for social sharing */}
              <meta
                property="og:title"
                content={`Check out ${userName}'s Keboon!`}
              />
              <meta property="og:description" content={description} />
              <meta
                property="og:image"
                content={`${window.location.origin}/icons/keboon-logo-white.png`}
              />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content="website" />

              {/* Twitter Card metadata */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content={`Check out ${userName}'s Keboon!`}
              />
              <meta name="twitter:description" content={description} />
              <meta
                name="twitter:image"
                content={`${window.location.origin}/icons/keboon-logo-white.png`}
              />
            </>
          );
        })()}
      </Helmet>
      <Header>
        <LeftNavContainer>
          <LeftNavLink href="https://app.keboon.net/">Main</LeftNavLink>
          {!isLoggedIn && (
            <LeftNavLink href="https://app.keboon.net/installation">
              Install
            </LeftNavLink>
          )}
        </LeftNavContainer>
        <a href="https://app.keboon.net" style={{ textDecoration: "none" }}>
          <Logo src={logo} alt="Keboon Logo" />
        </a>
        {!isLoggedIn && (
          <NavLinks>
            <NavLink onClick={handleSignIn}>Sign In</NavLink>
            <NavLink onClick={handleSignUp}>Sign Up</NavLink>
          </NavLinks>
        )}
      </Header>
      <MainContainer>
        <Title>Welcome to {userName}'s Keboon!</Title>
        <TabContainer>
          <TabButton
            $active={activeTab === "keboon"}
            onClick={() => setActiveTab("keboon")}
          >
            My Keboon
          </TabButton>
          <TabButton
            $active={activeTab === "fundraise"}
            onClick={() => setActiveTab("fundraise")}
          >
            Support Keboon
          </TabButton>
        </TabContainer>
        {activeTab === "keboon" ? (
          <>
            {isLoggedIn ? (
              shareId !== userProfile?._id && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <SaveKeboonButton
                    onClick={handleSaveKeboon}
                    disabled={isSavingKeboon}
                  >
                    {isSavingKeboon ? "Saving..." : "Save Keboon"}
                  </SaveKeboonButton>
                  <ShareKeboonButton
                    $withSaveButton
                    onClick={() => {
                      const productNames = products
                        .map((product) => product.name)
                        .filter(
                          (name, index, self) => self.indexOf(name) === index
                        )
                        .slice(0, 5);
                      const shareText =
                        productNames.length > 0
                          ? `Check out ${userName}'s Keboon!\n\nAvailable: ${productNames.join(
                              ", "
                            )}${
                              productNames.length < products.length
                                ? " and more!"
                                : ""
                            }`
                          : `Check out ${userName}'s Keboon!`;

                      if (navigator.share) {
                        navigator
                          .share({
                            title: `Check out ${userName}'s Keboon!`,
                            text: shareText,
                            url: window.location.href,
                          })
                          .catch((err) => {
                            navigator.clipboard.writeText(window.location.href);
                            alert("Link copied to clipboard!");
                          });
                      } else {
                        navigator.clipboard.writeText(window.location.href);
                        alert("Link copied to clipboard!");
                      }
                    }}
                  >
                    Share Keboon
                  </ShareKeboonButton>
                </div>
              )
            ) : (
              <ShareKeboonButton
                onClick={() => {
                  const productNames = products
                    .map((product) => product.name)
                    .filter((name, index, self) => self.indexOf(name) === index)
                    .slice(0, 5);
                  const shareText =
                    productNames.length > 0
                      ? `Check out ${userName}'s Keboon!\n\nAvailable: ${productNames.join(
                          ", "
                        )}${
                          productNames.length < products.length
                            ? " and more!"
                            : ""
                        }`
                      : `Check out ${userName}'s Keboon!`;

                  if (navigator.share) {
                    navigator
                      .share({
                        title: `Check out ${userName}'s Keboon!`,
                        text: shareText,
                        url: window.location.href,
                      })
                      .catch((err) => {
                        navigator.clipboard.writeText(window.location.href);
                        alert("Link copied to clipboard!");
                      });
                  } else {
                    navigator.clipboard.writeText(window.location.href);
                    alert("Link copied to clipboard!");
                  }
                }}
              >
                Share Keboon
              </ShareKeboonButton>
            )}

            {keboonPhotos.length > 0 && (
              <KeboonInfoSection>
                <PhotoCarousel>
                  <CarouselButton onClick={handlePrevPhoto}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </CarouselButton>
                  <CarouselPhoto
                    src={keboonPhotos[currentPhotoIndex]}
                    alt={`Keboon photo ${currentPhotoIndex + 1}`}
                    onError={() => handleImageError(currentPhotoIndex)}
                  />
                  <CarouselButton onClick={handleNextPhoto}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </CarouselButton>
                </PhotoCarousel>
                {keboonIntro && (
                  <KeboonIntro>{renderIntroduction(keboonIntro)}</KeboonIntro>
                )}
              </KeboonInfoSection>
            )}

            <SearchBarWrapper>
              <SearchBar
                type="text"
                placeholder="Search products"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIcon>
            </SearchBarWrapper>

            <MapComponent center={mapCenter} zoom={16}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <ChangeMapView center={mapCenter} />
              {userLocation && (
                <Marker
                  position={[userLocation.latitude, userLocation.longitude]}
                  icon={userIcon}
                >
                  <Popup>You are here</Popup>
                </Marker>
              )}
              {filteredProducts.map(
                (product) =>
                  product.location && (
                    <Marker
                      key={product._id}
                      position={[product.location.lat, product.location.lon]}
                      icon={productIcon}
                    >
                      <Popup>
                        <strong>{product.name}</strong>
                        <br />
                        Price: RM{product.price}/{product.unit}
                        <br />
                        Distance: {product.distance.toFixed(2)} km
                      </Popup>
                    </Marker>
                  )
              )}
            </MapComponent>

            {Object.entries(groupedProducts).map(([date, dateProducts]) => (
              <DateGroup key={date}>
                <DateHeader>{formatDate(date)}</DateHeader>
                {dateProducts.map((product) => (
                  <ProductCard key={product._id}>
                    <ProductImage src={product.image} alt={product.name} />
                    <ProductInfo>
                      <ProductName>{product.name}</ProductName>
                      <ProductPrice>
                        RM{product.price}/{product.unit}
                      </ProductPrice>
                      <LocationLink
                        onClick={() =>
                          handleLocationClick(
                            product.location.lat,
                            product.location.lon
                          )
                        }
                      >
                        {product.locationDetail}
                      </LocationLink>
                      {product.priceAnalysis && (
                        <>
                          <PriceComparisonText
                            status={product.priceAnalysis.status}
                          >
                            {product.priceAnalysis.status === "below" &&
                              "Below market price"}
                            {product.priceAnalysis.status === "within" &&
                              "Market price"}
                            {product.priceAnalysis.status === "above" &&
                              "Above market price"}
                          </PriceComparisonText>
                          <MarketPriceInfo>
                            Market Avg: RM
                            {product.priceAnalysis.meanPrice.toFixed(2)}/
                            {product.priceAnalysis.referenceUnit}
                            {normalizeUnit(product.unit) !==
                              normalizeUnit(
                                product.priceAnalysis.referenceUnit
                              ) && (
                              <div>
                                Your price (converted): RM
                                {product.priceAnalysis.convertedPrice.toFixed(
                                  2
                                )}
                                /{product.priceAnalysis.referenceUnit}
                              </div>
                            )}
                            <PriceInfoIcon>
                              <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                              <Tooltip>
                                Market prices are based on average retail prices
                                across Malaysia.
                                {normalizeUnit(product.unit) !==
                                  normalizeUnit(
                                    product.priceAnalysis.referenceUnit
                                  ) &&
                                  ` Price has been converted from ${product.unit} to ${product.priceAnalysis.referenceUnit} for comparison.`}
                              </Tooltip>
                            </PriceInfoIcon>
                          </MarketPriceInfo>
                        </>
                      )}
                      {!product.priceAnalysis && (
                        <MarketPriceInfo>
                          <em>No market price reference available</em>
                        </MarketPriceInfo>
                      )}
                      <InterestedButton
                        onClick={() => handleInterested(product)}
                        disabled={
                          isInterestLoading &&
                          interestedProductId === product._id
                        }
                      >
                        {isInterestLoading &&
                        interestedProductId === product._id ? (
                          <>
                            <SpinnerIcon icon={faSpinner} spin />
                            Loading...
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faHeart} /> Interested
                          </>
                        )}
                      </InterestedButton>
                    </ProductInfo>
                    {product.distance !== Infinity && (
                      <DistanceTag>
                        {product.distance.toFixed(2)} km
                      </DistanceTag>
                    )}
                  </ProductCard>
                ))}
              </DateGroup>
            ))}

            {currentProducts.length === 0 && (
              <ErrorMessage>
                No products available in this shared Keboon.
              </ErrorMessage>
            )}

            <PaginationContainer>
              {[...Array(pageCount)].map((_, i) => (
                <PageButton
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </PageButton>
              ))}
            </PaginationContainer>
          </>
        ) : (
          <FundraiseTab userId={shareId} />
        )}

        {showModal && (
          <Modal>
            <ModalContent>
              <h3>Express Interest</h3>
              {/* Only show Name and Mobile Number fields if the user is not logged in */}
              {!isLoggedIn && (
                <>
                  <ModalInput
                    type="text"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <ModalInput
                    type="tel"
                    placeholder="Your Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </>
              )}
              {/* Quantity field is shown to all users */}
              <ModalInput
                type="number"
                placeholder="Quantity"
                value={quantity}
                onChange={handleQuantityChange}
                min="1"
              />
              <p>
                Product Price: RM{selectedProduct?.price}/
                {selectedProduct?.unit}
              </p>
              <TotalPriceDisplay>
                Total Price: RM{totalPrice.toFixed(2)}
              </TotalPriceDisplay>
              <ModalButton
                onClick={handleSubmitInterest}
                disabled={isInterestLoading}
              >
                {isInterestLoading ? "Submitting..." : "Submit"}
              </ModalButton>
              <ModalButton onClick={() => setShowModal(false)}>
                Cancel
              </ModalButton>
            </ModalContent>
          </Modal>
        )}

        {showWhatsAppPopup && (
          <Modal>
            <ModalContent>
              <h3>Contact Seller via WhatsApp</h3>
              <p>Would you like to contact the seller on WhatsApp?</p>
              <ModalButton
                onClick={() => {
                  console.log("Selected Product:", selectedProduct);
                  console.log("Current Quantity:", quantity);
                  console.log("Current Total Price:", totalPrice);

                  const userName = isLoggedIn ? userProfile?.name : name;
                  const message = encodeURIComponent(
                    `Hi! I'm interested in your Keboon product.\n\n` +
                      `I would like to order:\n` +
                      `Product: ${selectedProduct.name}\n` +
                      `Quantity: ${quantity} ${selectedProduct.unit}\n` + // Use quantity directly from state
                      `Total Price: RM${totalPrice.toFixed(2)}\n\n` + // Use totalPrice directly from state
                      `My name is ${userName}. Is this still available?`
                  );

                  const formattedPhone = selectedProduct.sellerPhone.replace(
                    /\D/g,
                    ""
                  );
                  const phoneWithCountryCode = formattedPhone.startsWith("60")
                    ? formattedPhone
                    : "60" + formattedPhone;

                  window.open(
                    `https://wa.me/${phoneWithCountryCode}?text=${message}`,
                    "_blank"
                  );
                  setShowWhatsAppPopup(false);
                  setShowModal(false);
                }}
              >
                Yes
              </ModalButton>
              <ModalButton
                onClick={() => {
                  setShowWhatsAppPopup(false);
                  setShowModal(false);
                }}
              >
                No
              </ModalButton>
            </ModalContent>
          </Modal>
        )}
      </MainContainer>

      {isLoggedIn && !isDirectAccess && (
        <BottomNavigation>
          <NavItem to="/main">
            <NavIcon>
              <FontAwesomeIcon icon={faHome} />
            </NavIcon>
            Home
          </NavItem>
          <NavItem to="/add-product">
            <NavIcon>
              <FontAwesomeIcon icon={faPlus} />
            </NavIcon>
            Add Product
          </NavItem>
          <NavItem to="/wallet">
            <NavIcon>
              <FontAwesomeIcon icon={faWallet} />
            </NavIcon>
            Wallet
          </NavItem>
          <NavItem to="/my-listing">
            <NavIcon>
              <FontAwesomeIcon icon={faList} />
            </NavIcon>
            My Listing
          </NavItem>
          <NavItem to="/profile">
            <NavIcon>
              <FontAwesomeIcon icon={faUser} />
            </NavIcon>
            Profile
          </NavItem>
        </BottomNavigation>
      )}
    </PageWrapper>
  );
}

export default SharedKeboonPage;
