import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
`;

const InstallButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
`;

const BackButton = styled.button`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const Instructions = styled.div`
  margin-top: 30px;
  text-align: left;
`;

const Message = styled.p`
  margin-top: 20px;
  color: #666;
`;

const InstallationPage = () => {
  const [isInstallable, setIsInstallable] = useState(false);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  const isDesktop = !isIOS && !isAndroid;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      window.deferredPrompt = e;
      setIsInstallable(true);
    };

    const handleAppInstalled = () => {
      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null;
      setIsInstallable(false);
      // Optionally show a success message or redirect
      alert('App successfully installed!');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstall = async (platform) => {
    if (platform === 'android') {
      if (window.deferredPrompt) {
        try {
          const deferredPrompt = window.deferredPrompt;
          // Show the install prompt
          await deferredPrompt.prompt();
          // Wait for the user to respond to the prompt
          const { outcome } = await deferredPrompt.userChoice;
          console.log(`User response to the install prompt: ${outcome}`);
          // Clear the deferredPrompt
          window.deferredPrompt = null;
          setIsInstallable(false);
        } catch (error) {
          console.error('Error during installation:', error);
          alert('To install, open this page in Chrome and tap "Add to Home screen".');
        }
      } else {
        alert('To install, open this page in Chrome and tap "Add to Home screen".');
      }
    } else if (platform === 'ios') {
      // For iOS, show more specific instructions based on iOS version
      let iOSVersion = 13; // default version
      try {
        const matches = navigator.userAgent.match(/OS (\d+)_/);
        if (matches) {
          iOSVersion = parseInt(matches[1]);
        }
      } catch (error) {
        console.error('Error detecting iOS version:', error);
      }

      if (iOSVersion >= 15) {
        alert('To install on iOS:\n1. Tap the share button (square with arrow) at the top of the browser\n2. Scroll down and tap "Add to Home Screen"\n3. Tap "Add" in the top right corner');
      } else {
        alert('To install on iOS, tap the share button and then "Add to Home Screen".');
      }
    }
  };

  const handleBackClick = () => {
    window.location.href = 'https://app.keboon.net';
  };

  return (
    <PageContainer>
      <Title>Install Keboon App</Title>
      
      <ButtonContainer>
        {isAndroid && (
          <InstallButton 
            onClick={() => handleInstall('android')}
            disabled={!isInstallable}
            style={{
              backgroundColor: isInstallable ? '#4CAF50' : '#888'
            }}
          >
            {isInstallable ? 'Install for Android' : 'Open in Chrome to Install'}
          </InstallButton>
        )}
        {isIOS && (
          <InstallButton onClick={() => handleInstall('ios')}>
            Install for iOS
          </InstallButton>
        )}
      </ButtonContainer>
      
      {isDesktop && (
        <Message>
          Please visit this page on your mobile device to install the app.
        </Message>
      )}
      
      <BackButton onClick={handleBackClick}>
        Return to Keboon App
      </BackButton>
      
      <Instructions>
        <h2>Installation Instructions:</h2>
        {isAndroid && (
          <>
            <h3>For Android:</h3>
            <ol>
              <li>Open this page in Chrome browser</li>
              <li>Tap the "Install for Android" button above</li>
              <li>If prompted, tap "Add to Home screen"</li>
              <li>The app will be installed on your home screen</li>
            </ol>
          </>
        )}
        {isIOS && (
          <>
            <h3>For iOS:</h3>
            <ol>
              <li>Open this page in Safari browser</li>
              <li>Tap the share button at the bottom of the browser</li>
              <li>Scroll down and tap "Add to Home Screen"</li>
              <li>Tap "Add" in the top right corner</li>
              <li>The app will be installed on your home screen</li>
            </ol>
          </>
        )}
      </Instructions>
    </PageContainer>
  );
};

export default InstallationPage;