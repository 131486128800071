import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import styled from "styled-components";
import MainAppPage from "./pages/MainAppPage";
import LoginPage from "./pages/LoginPage";
import SignUpForm from "./components/SignUpForm";
import MainLoggedIn from "./pages/MainLoggedIn";
import AddProductPage from "./pages/AddProductPage";
import AdminDashboard from "./pages/AdminDashboard";
import InstallationPage from "./pages/InstallationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import EditProductPage from "./pages/EditProductPage";
import UserProductsListPage from "./pages/UserProductsListPage";
import UserProfilePage from "./pages/UserProfilePage";
import WalletScreen from "./pages/WalletScreen";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import SharedKeboonPage from "./pages/SharedKeboonPage";
import KeboonSupplies from "./pages/KeboonSupplies"; // Add this import

const AppContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  return token ? children : <Navigate to="/main" replace />;
};

// ProtectedAdminRoute component
const ProtectedAdminRoute = ({ children }) => {
  const token = localStorage.getItem("authToken");
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  return token && isAdmin ? children : <Navigate to="/main" replace />;
};

const MainRedirect = () => {
  const token = localStorage.getItem("authToken");
  return token ? <Navigate to="/main" replace /> : <MainAppPage />;
};

function App() {
  useEffect(() => {
    const updateVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", updateVH);
    updateVH();

    return () => window.removeEventListener("resize", updateVH);
  }, []);

  return (
    <AppContainer className="App scrollable-content">
      <Router>
        <Routes>
          <Route path="/" element={<MainRedirect />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route
            path="/main/*"
            element={
              <PrivateRoute>
                <MainLoggedIn />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-product"
            element={
              <PrivateRoute>
                <AddProductPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-listing"
            element={
              <PrivateRoute>
                <UserProductsListPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-product/:id"
            element={
              <PrivateRoute>
                <EditProductPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <PrivateRoute>
                <WalletScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/success"
            element={
              <PrivateRoute>
                <SuccessPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/cancel"
            element={
              <PrivateRoute>
                <CancelPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedAdminRoute>
                <AdminDashboard />
              </ProtectedAdminRoute>
            }
          />
          <Route path="/installation" element={<InstallationPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/shared-keboon/:shareId"
            element={<SharedKeboonPage />}
          />
        </Routes>
      </Router>
    </AppContainer>
  );
}

export default App;
