import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faEdit,
  faTrash,
  faPhone,
  faSpinner,
  faCheck,
  faShare,
  faCopy,
  faCamera,
  faArrowLeft,
  faArrowRight,
  faExclamationTriangle,
  faEnvelope,
  faBookmark,
  faLink,
  faExternalLinkAlt,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./icons/keboon-logo-white.png";
import Resizer from "react-image-file-resizer";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const PageButton = styled.button`
  padding: 8px 12px;
  background-color: ${(props) => (props.active ? "#377e62" : "#ffffff")};
  color: ${(props) => (props.active ? "#ffffff" : "#377e62")};
  border: 1px solid #377e62;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? "#377e62" : "#e0f2e9")};
  }

  &:disabled {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: #666666;
  font-size: 14px;
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const EmailDisplay = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #377e62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ResetPasswordButton = styled(Button)`
  background-color: #ffa726; /* Orange background */
  &:hover {
    background-color: #fb8c00; /* Darker orange on hover */
  }
`;

const LogoutButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #d32f2f;
  }
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377e62;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4caf50;
  text-align: center;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FeedbackTextarea = styled.textarea`
  width: 95%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const SubmitFeedbackButton = styled(Button)``;

const ShareButton = styled(Button)`
  background-color: #4267b2;
  &:hover {
    background-color: #365899;
  }
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const PhotoUploadButton = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #377e62;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const PhotoPreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PhotoPreview = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 10px;
`;

const CarouselButton = styled.button`
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #377e62;
  }
`;

const IntroductionTextarea = styled.textarea`
  width: 95%;
  height: 100px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const DeletePhotoButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(244, 67, 54, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(211, 47, 47, 1);
  }
`;

const PhotoContainer = styled.div`
  position: relative;
`;

const ResetPasswordModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const SavedKeboonList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SavedKeboonItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const SavedKeboonLink = styled.span`
  color: #377e62;
  flex-grow: 1;
  margin-right: 10px;
`;

const KeboonButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const VisitButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
`;

const RemoveButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #d32f2f;
  }
`;

const DropdownSection = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const DropdownTitle = styled.h3`
  margin: 0;
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  transition: transform 0.3s ease;
`;

const DropdownContent = styled.div`
  padding: 20px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

function UserProfilePage() {
  const [bankDetails, setBankDetails] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [keboonPhotos, setKeboonPhotos] = useState([]);
  const [keboonIntro, setKeboonIntro] = useState("");
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isSavingKeboon, setIsSavingKeboon] = useState(false);
  const [keboonSaveSuccess, setKeboonSaveSuccess] = useState(false);
  const [keboonSaveFailed, setKeboonSaveFailed] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const [openSections, setOpenSections] = useState({
    profile: false,
    keboonInfo: false,
    savedKeboons: false,
    feedback: false,
  });
  const navigate = useNavigate();
  const [savedKeboons, setSavedKeboons] = useState([]);
  const [newKeboonLink, setNewKeboonLink] = useState("");
  const [isSavingKeboonLink, setIsSavingKeboonLink] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of saved Keboons to show per page
  // Calculate pagination values
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = savedKeboons.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(savedKeboons.length / itemsPerPage);

  // Add pagination handlers
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const fetchSavedKeboons = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/user/saved-keboons`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setSavedKeboons(response.data);
      console.log("Fetched saved Keboons:", response.data);
    } catch (error) {
      console.error("Error fetching saved Keboons:", error);
      setError("Failed to fetch saved Keboons. Please try again.");
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(
          `${window.location.origin}/api/config`
        );
        setApiUrl(response.data.backendUrl);
      } catch (error) {
        console.error("Error fetching API config:", error);
        setError("Unable to connect to the server. Please try again later.");
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const [profileRes, bankRes] = await Promise.all([
          axios.get(`${apiUrl}/api/user/profile`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }),
          axios.get(`${apiUrl}/api/user/bank-details`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }),
        ]);

        setUserEmail(profileRes.data.email);
        setUserName(profileRes.data.name || "");
        setUserMobile(profileRes.data.mobileNumber || "");
        setKeboonPhotos(profileRes.data.keboonPhotos || []);
        setKeboonIntro(profileRes.data.keboonIntro || "");
        setBankDetails(bankRes.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (apiUrl) {
      fetchUserProfile();
      fetchSavedKeboons();
    }
  }, [apiUrl, navigate]);

  const processImage = (file) => {
    return new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        200,
        200
      );
    });
  };

  const handlePhotoUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length + keboonPhotos.length > 5) {
      alert("You can only have a maximum of 5 photos.");
      return;
    }

    const newPhotos = await Promise.all(
      files.map(async (file) => {
        try {
          const processedImage = await processImage(file);
          return processedImage;
        } catch (error) {
          console.error("Error processing image:", error);
          return null;
        }
      })
    );

    const validPhotos = newPhotos.filter((photo) => photo !== null);
    setKeboonPhotos((prevPhotos) => [...prevPhotos, ...validPhotos]);
  };

  const handleDeletePhoto = (indexToDelete) => {
    setKeboonPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleNextPhoto = () => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % keboonPhotos.length);
  };

  const handlePrevPhoto = () => {
    setCurrentPhotoIndex(
      (prevIndex) => (prevIndex - 1 + keboonPhotos.length) % keboonPhotos.length
    );
  };

  const handleSaveProfile = async () => {
    setIsSaving(true);
    setSaveSuccess(false);
    try {
      // First update the user profile
      const userResponse = await axios.put(
        `${apiUrl}/api/user/profile`,
        {
          name: userName,
          mobileNumber: userMobile,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      // Then update bank details
      const bankResponse = await axios.post(
        `${apiUrl}/api/user/bank-details`,
        bankDetails,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setBankDetails(bankResponse.data);
      setSuccessMessage("Profile and bank details updated successfully");
      setSaveSuccess(true);
      setError("");
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile. Please try again.");
      setSuccessMessage("");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveKeboonInfo = async () => {
    setIsSavingKeboon(true);
    setKeboonSaveSuccess(false);
    setKeboonSaveFailed(false);
    try {
      const existingPhotos = keboonPhotos.filter((photo) =>
        photo.startsWith("http")
      );
      const newPhotos = keboonPhotos.filter(
        (photo) => !photo.startsWith("http")
      );

      const response = await axios.put(
        `${apiUrl}/api/user/keboon-info`,
        {
          existingPhotos,
          newPhotos,
          introduction: keboonIntro,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setKeboonPhotos(response.data.keboonPhotos);
      setSuccessMessage("Keboon information updated successfully");
      setError("");
      setKeboonSaveSuccess(true);
      setTimeout(() => setKeboonSaveSuccess(false), 3000);
    } catch (error) {
      console.error("Error updating Keboon information:", error);
      setError("Failed to update Keboon information. Please try again.");
      setSuccessMessage("");
      setKeboonSaveFailed(true);
      setTimeout(() => setKeboonSaveFailed(false), 3000);
    } finally {
      setIsSavingKeboon(false);
    }
  };

  const handleLogout = () => {
    // Clear all auth-related data
    localStorage.removeItem("authToken");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("loginTimestamp");

    // Broadcast logout event to other tabs
    window.localStorage.setItem("logout", Date.now());

    // Remove Authorization header
    delete axios.defaults.headers.common["Authorization"];

    // Navigate to main app page
    navigate("/");
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/submit-feedback`,
        { feedback },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setFeedback("");
      setSuccessMessage(
        response.data.message || "Feedback submitted successfully"
      );
      setError("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setError(
        error.response?.data?.message ||
          "Failed to submit feedback. Please try again."
      );
      setSuccessMessage("");
    }
  };

  const handleShareMyKeboon = async () => {
    setIsSharing(true);
    try {
      // First get the share URL
      const response = await axios.post(
        `${apiUrl}/api/user/share-keboon`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      // Then get the user's products
      const productsResponse = await axios.get(`${apiUrl}/api/user/products`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      const productNames = productsResponse.data
        .map((product) => product.name)
        .filter((name, index, self) => self.indexOf(name) === index)
        .slice(0, 5);

      const shareText =
        productNames.length > 0
          ? `Check out ${userName}'s Keboon!\n\nAvailable: ${productNames.join(
              ", "
            )}${
              productNames.length < productsResponse.data.length
                ? " and more!"
                : ""
            }`
          : `Check out ${userName}'s Keboon!`;

      if (navigator.share) {
        navigator
          .share({
            title: `Check out ${userName}'s Keboon!`,
            text: shareText,
            url: response.data.shareUrl,
          })
          .catch((err) => {
            navigator.clipboard.writeText(response.data.shareUrl);
            setSuccessMessage("Link copied to clipboard!");
          });
      } else {
        navigator.clipboard.writeText(response.data.shareUrl);
        setSuccessMessage("Link copied to clipboard!");
      }

      setShareUrl(response.data.shareUrl);
      setError("");
    } catch (error) {
      console.error("Error sharing Keboon:", error);
      setError("Failed to share your Keboon. Please try again.");
      setSuccessMessage("");
    } finally {
      setIsSharing(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsResettingPassword(true);
    setResetPasswordMessage("");
    setError("");

    try {
      const response = await axios.post(
        `${apiUrl}/api/forgot-password`,
        { email: resetPasswordEmail },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setResetPasswordMessage(
        response.data.message ||
          "Password reset link has been sent to your email."
      );
      setTimeout(() => {
        setShowResetPasswordModal(false);
        setResetPasswordEmail("");
        setResetPasswordMessage("");
      }, 3000);
    } catch (err) {
      console.error("Reset password error:", err);
      setError(
        err.response?.data?.message ||
          "Error sending reset link. Please try again."
      );
    } finally {
      setIsResettingPassword(false);
    }
  };

  const handleAddSavedKeboon = async (e) => {
    e.preventDefault();
    setIsSavingKeboonLink(true);
    setError("");
    setSuccessMessage("");

    try {
      await axios.post(
        `${apiUrl}/api/user/saved-keboons`,
        { keboonLink: newKeboonLink },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setNewKeboonLink("");
      setSuccessMessage("Keboon link saved successfully!");
      fetchSavedKeboons(); // Fetch updated list after saving
    } catch (error) {
      console.error("Error saving Keboon link:", error);
      setError(
        error.response?.data?.message ||
          "Failed to save Keboon link. Please try again."
      );
    } finally {
      setIsSavingKeboonLink(false);
    }
  };

  const handleRemoveSavedKeboon = async (keboonId) => {
    try {
      await axios.delete(`${apiUrl}/api/user/saved-keboons/${keboonId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setSuccessMessage("Keboon link removed successfully!");
      fetchSavedKeboons(); // Fetch updated list after removing
    } catch (error) {
      console.error("Error removing Keboon link:", error);
      setError("Failed to remove Keboon link. Please try again.");
    }
  };

  const handleVisitKeboon = (keboonLink) => {
    try {
      // Create URL object to parse the keboonLink
      const url = new URL(keboonLink);
      // Get just the pathname (e.g., "/shared-keboon/67164c5b312ed215bd90f545")
      const path = url.pathname;
      // Navigate to the path
      navigate(path);
    } catch (error) {
      console.error("Invalid URL:", error);
      // Fallback to direct navigation if URL parsing fails
      navigate(keboonLink);
    }
  };

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>User Profile</Subtitle>
      </Header>
      <MainContainer>
        {isLoading ? (
          <LoadingMessage>Loading user profile...</LoadingMessage>
        ) : (
          <>
            <DropdownSection>
              <DropdownHeader onClick={() => toggleSection("profile")}>
                <DropdownTitle>Profile Information</DropdownTitle>
                <DropdownIcon
                  icon={openSections.profile ? faChevronUp : faChevronDown}
                />
              </DropdownHeader>
              <DropdownContent isOpen={openSections.profile}>
                <EmailDisplay>Email: {userEmail}</EmailDisplay>
                <Input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Name"
                />
                <Input
                  type="tel"
                  value={userMobile}
                  onChange={(e) => setUserMobile(e.target.value)}
                  placeholder="Mobile Number"
                />
                <div
                  style={{
                    marginTop: "20px",
                    borderTop: "1px solid #eee",
                    paddingTop: "20px",
                  }}
                >
                  <h4 style={{ color: "#377e62", marginBottom: "15px" }}>
                    Bank Details
                  </h4>
                  <p
                    style={{
                      fontSize: "0.9em",
                      color: "#666",
                      marginBottom: "15px",
                    }}
                  >
                    *Required for campaign creation and receiving funds
                  </p>
                  <Input
                    type="text"
                    value={bankDetails?.bankName || ""}
                    onChange={(e) =>
                      setBankDetails((prev) => ({
                        ...prev,
                        bankName: e.target.value,
                      }))
                    }
                    placeholder="Bank Name"
                  />
                  <Input
                    type="text"
                    value={bankDetails?.accountNumber || ""}
                    onChange={(e) =>
                      setBankDetails((prev) => ({
                        ...prev,
                        accountNumber: e.target.value,
                      }))
                    }
                    placeholder="Account Number"
                  />
                  <Input
                    type="text"
                    value={bankDetails?.accountHolder || ""}
                    onChange={(e) =>
                      setBankDetails((prev) => ({
                        ...prev,
                        accountHolder: e.target.value,
                      }))
                    }
                    placeholder="Account Holder Name"
                  />
                </div>

                <Button onClick={handleSaveProfile} disabled={isSaving}>
                  {isSaving ? (
                    <>
                      <ButtonIcon icon={faSpinner} spin /> Saving...
                    </>
                  ) : saveSuccess ? (
                    <>
                      <ButtonIcon icon={faCheck} /> Saved!
                    </>
                  ) : (
                    "Save Profile"
                  )}
                </Button>
              </DropdownContent>
            </DropdownSection>

            <DropdownSection>
              <DropdownHeader onClick={() => toggleSection("keboonInfo")}>
                <DropdownTitle>Keboon Information</DropdownTitle>
                <DropdownIcon
                  icon={openSections.keboonInfo ? faChevronUp : faChevronDown}
                />
              </DropdownHeader>
              <DropdownContent isOpen={openSections.keboonInfo}>
                <PhotoUploadButton>
                  <FontAwesomeIcon icon={faCamera} /> Upload Photos
                  <HiddenFileInput
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={handlePhotoUpload}
                  />
                </PhotoUploadButton>
                {keboonPhotos.length > 0 && (
                  <PhotoPreviewContainer>
                    <CarouselButton onClick={handlePrevPhoto}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </CarouselButton>
                    <PhotoContainer>
                      <PhotoPreview
                        src={keboonPhotos[currentPhotoIndex]}
                        alt={`Keboon photo ${currentPhotoIndex + 1}`}
                      />
                      <DeletePhotoButton
                        onClick={() => handleDeletePhoto(currentPhotoIndex)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </DeletePhotoButton>
                    </PhotoContainer>
                    <CarouselButton onClick={handleNextPhoto}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </CarouselButton>
                  </PhotoPreviewContainer>
                )}
                <IntroductionTextarea
                  value={keboonIntro}
                  onChange={(e) => setKeboonIntro(e.target.value)}
                  placeholder="Introduce your Keboon here..."
                />
                <Button
                  onClick={handleSaveKeboonInfo}
                  disabled={isSavingKeboon}
                  style={{
                    backgroundColor: keboonSaveSuccess
                      ? "#4caf50"
                      : keboonSaveFailed
                      ? "#f44336"
                      : "#52ae77",
                  }}
                >
                  {isSavingKeboon ? (
                    <>
                      <ButtonIcon icon={faSpinner} spin /> Saving...
                    </>
                  ) : keboonSaveSuccess ? (
                    <>
                      <ButtonIcon icon={faCheck} /> Updated!
                    </>
                  ) : keboonSaveFailed ? (
                    <>
                      <ButtonIcon icon={faExclamationTriangle} /> Failed
                    </>
                  ) : (
                    "Save Keboon Information"
                  )}
                </Button>
              </DropdownContent>
            </DropdownSection>

            <DropdownSection>
              <DropdownHeader onClick={() => toggleSection("savedKeboons")}>
                <DropdownTitle>Saved Keboon</DropdownTitle>
                <DropdownIcon
                  icon={openSections.savedKeboons ? faChevronUp : faChevronDown}
                />
              </DropdownHeader>
              <DropdownContent isOpen={openSections.savedKeboons}>
                <form onSubmit={handleAddSavedKeboon}>
                  <Input
                    type="url"
                    value={newKeboonLink}
                    onChange={(e) => setNewKeboonLink(e.target.value)}
                    placeholder="Enter Keboon link"
                    required
                  />
                  <Button type="submit" disabled={isSavingKeboonLink}>
                    {isSavingKeboonLink ? (
                      <>
                        <ButtonIcon icon={faSpinner} spin /> Saving...
                      </>
                    ) : (
                      <>
                        <ButtonIcon icon={faPlus} /> Add Keboon
                      </>
                    )}
                  </Button>
                </form>
                <SavedKeboonList>
                  {currentItems.map((keboon) => (
                    <SavedKeboonItem key={keboon.id}>
                      <SavedKeboonLink>
                        <FontAwesomeIcon icon={faLink} />{" "}
                        {keboon.keboonOwnerName}'s Keboon
                      </SavedKeboonLink>
                      <KeboonButtonGroup>
                        <VisitButton
                          onClick={() => handleVisitKeboon(keboon.keboonLink)}
                        >
                          <FontAwesomeIcon icon={faExternalLinkAlt} /> Visit
                        </VisitButton>
                        <RemoveButton
                          onClick={() => handleRemoveSavedKeboon(keboon.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} /> Remove
                        </RemoveButton>
                      </KeboonButtonGroup>
                    </SavedKeboonItem>
                  ))}
                </SavedKeboonList>
                {savedKeboons.length > itemsPerPage && (
                  <PaginationContainer>
                    <PageButton
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </PageButton>

                    {[...Array(totalPages)].map((_, index) => (
                      <PageButton
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        active={currentPage === index + 1}
                      >
                        {index + 1}
                      </PageButton>
                    ))}

                    <PageButton
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </PageButton>

                    <PageInfo>
                      Page {currentPage} of {totalPages}
                    </PageInfo>
                  </PaginationContainer>
                )}
              </DropdownContent>
            </DropdownSection>

            <DropdownSection>
              <DropdownHeader onClick={() => toggleSection("feedback")}>
                <DropdownTitle>Send Feedback</DropdownTitle>
                <DropdownIcon
                  icon={openSections.feedback ? faChevronUp : faChevronDown}
                />
              </DropdownHeader>
              <DropdownContent isOpen={openSections.feedback}>
                <FeedbackTextarea
                  value={feedback}
                  onChange={handleFeedbackChange}
                  placeholder="Enter your feedback here..."
                />
                <SubmitFeedbackButton onClick={handleSubmitFeedback}>
                  Submit Feedback
                </SubmitFeedbackButton>
              </DropdownContent>
            </DropdownSection>

            <ButtonContainer>
              <ShareButton onClick={handleShareMyKeboon} disabled={isSharing}>
                {isSharing ? (
                  <>
                    <ButtonIcon icon={faSpinner} spin /> Sharing...
                  </>
                ) : (
                  <>
                    <ButtonIcon icon={faShare} /> Share My Keboon
                  </>
                )}
              </ShareButton>
              <ResetPasswordButton
                onClick={() => setShowResetPasswordModal(true)}
              >
                Reset Password
              </ResetPasswordButton>
              <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </ButtonContainer>

            {error && <ErrorMessage>{error}</ErrorMessage>}
            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
          </>
        )}
      </MainContainer>

      {showResetPasswordModal && (
        <>
          <ModalOverlay onClick={() => setShowResetPasswordModal(false)} />
          <ResetPasswordModal>
            <h3>Reset Password</h3>
            <form onSubmit={handleResetPassword}>
              <Input
                type="email"
                placeholder="Enter your email"
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                required
              />
              <ResetPasswordButton type="submit" disabled={isResettingPassword}>
                {isResettingPassword ? (
                  <>
                    <ButtonIcon icon={faSpinner} spin /> Sending...
                  </>
                ) : (
                  <>
                    <ButtonIcon icon={faEnvelope} /> Send Reset Link
                  </>
                )}
              </ResetPasswordButton>
            </form>
            {resetPasswordMessage && (
              <SuccessMessage>{resetPasswordMessage}</SuccessMessage>
            )}
          </ResetPasswordModal>
        </>
      )}

      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} />
          </NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon>
            <FontAwesomeIcon icon={faPlus} />
          </NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon>
            <FontAwesomeIcon icon={faWallet} />
          </NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon>
            <FontAwesomeIcon icon={faList} />
          </NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon>
            <FontAwesomeIcon icon={faUser} />
          </NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default UserProfilePage;
