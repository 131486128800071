import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faUpload,
  faEye,
  faEdit,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./icons/keboon-logo-white.png";

const LocationLink = styled.p`
  margin: 0 0 5px 0;
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const PriceComparisonText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  color: ${(props) =>
    props.status === "above"
      ? "#FF5722"
      : props.status === "within"
      ? "#4CAF50"
      : "#4CAF50"};
`;

const MarketPriceInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 2px;
`;

const PreviewToggle = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  background-color: #377e62;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    background-color: #52ae77;
  }
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductPreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.div`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52ae77;
`;

const PostedByLink = styled.div`
  color: #377e62;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
`;

const PriceInfoWrapper = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 2px;
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  width: max-content;
  max-width: 200px;
  display: none;
`;

const PriceInfoIcon = styled.span`
  position: relative;
  margin-left: 5px;
  cursor: help;

  &:hover ${Tooltip} {
    display: block;
  }
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-bottom: 10px;
`;

const ImageUpload = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #377e62;
  }
`;

const UploadIcon = styled.span`
  color: #377e62;
  font-size: 48px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #377e62;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #377e62;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="%23377E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 14px auto;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #377e62;
  }
`;

const PriceInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const PriceLabel = styled.label`
  margin-right: 10px;
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const CancelButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const PostButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? "#a0a0a0" : "#377E62")};
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "#a0a0a0" : "#52AE77")};
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #377e62;
  }
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
  z-index: 1;
`;

const SuccessPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PopupButton = styled(Button)`
  background-color: #377e62;
  color: white;
  margin-top: 15px;

  &:hover {
    background-color: #52ae77;
  }
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const defaultCenter = [3.139003, 101.686855]; // Kuala Lumpur center coordinates

const unitOptions = [
  { value: "piece", label: "Piece/Unit" },
  { value: "kg", label: "Kilogram (kg)" },
  { value: "100g", label: "100 gram" },
  { value: "L", label: "Liter (L)" },
  { value: "mL", label: "Milliliter (mL)" },
];

const locationIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const ModalButton = styled(Button)`
  margin: 10px;
`;

const PriceAnalysisCard = styled.div`
  border: 1px solid ${(props) => (props.isWithinRange ? "#4CAF50" : "#FF5722")};
  border-radius: 8px;
  padding: 12px;
  margin: 10px 0;
  background-color: ${(props) =>
    props.isWithinRange ? "rgba(76, 175, 80, 0.1)" : "rgba(255, 87, 34, 0.1)"};
`;

const PriceSelect = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const PriceDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PriceOption = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const unitGroups = {
  weight: ["kg", "100g"],
  volume: ["L", "mL"],
  piece: ["piece", "unit", "biji", "set"],
};

// Base unit conversion factors
const baseConversions = {
  kg: {
    base: 1000, // base unit is grams
    "100g": 10,
  },
  "100g": {
    base: 100, // base unit is grams
    kg: 0.1,
  },
  L: {
    base: 1000, // base unit is milliliters
    mL: 1000,
  },
  mL: {
    base: 1, // base unit is milliliters
    L: 0.001,
  },
  piece: {
    base: 1,
  },
};

const extractNumericValue = (unitStr) => {
  if (!unitStr) return 1;

  // Remove any ± or +- symbols first
  const cleanStr = unitStr.replace(/[±\+\-]/g, "").trim();

  // Look for numeric values, including decimals
  const matches = cleanStr.match(/(\d*\.?\d+)/);

  // Return the numeric value or 1 if none found
  return matches ? parseFloat(matches[1]) : 1;
};

const normalizeUnit = (unit) => {
  if (!unit) return null;

  // Clean the input string
  const cleanUnit = unit.trim().toLowerCase();

  // Try to match against each normalized unit's patterns
  for (const [normalizedUnit, patterns] of Object.entries(unitNormalization)) {
    for (const pattern of patterns) {
      if (pattern.test(cleanUnit)) {
        return normalizedUnit;
      }
    }
  }

  return unit;
};

const stripNumericPrefix = (unit) => {
  // Remove any numeric prefix from the unit
  return unit.replace(/^\d+\s*/, "").trim();
};

const convertPrice = (price, fromUnit, toUnit) => {
  if (!price || !fromUnit || !toUnit) return price;

  // Get numeric multipliers from units
  const fromMultiplier = extractNumericValue(fromUnit);
  const toMultiplier = extractNumericValue(toUnit);

  // Normalize units
  const normalizedFromUnit = normalizeUnit(fromUnit);
  const normalizedToUnit = normalizeUnit(stripNumericPrefix(toUnit));

  if (!normalizedFromUnit || !normalizedToUnit) return price;

  // If units are the same type but different quantities (e.g., 1kg vs 10kg)
  if (normalizedFromUnit === normalizedToUnit) {
    return price * (fromMultiplier / toMultiplier);
  }

  // Get base conversion factor
  const conversionFactor =
    baseConversions[normalizedFromUnit]?.[normalizedToUnit];

  if (conversionFactor !== undefined) {
    // Convert price considering both the base conversion and the multipliers
    return price * conversionFactor * (fromMultiplier / toMultiplier);
  }

  // Try reverse conversion if direct conversion not found
  const reverseConversionFactor =
    baseConversions[normalizedToUnit]?.[normalizedFromUnit];

  if (reverseConversionFactor !== undefined) {
    return (
      price * (1 / reverseConversionFactor) * (fromMultiplier / toMultiplier)
    );
  }

  // If no conversion possible, return original price
  return price;
};

const unitNormalization = {
  kg: [
    /\b(?:\d*\.?\d*\s*)?(?:kg|kgs|kilo|kilos|kilogram|kilograms|Kg|KG)\b/i,
    /±?\s*\d*\.?\d*\s*kg\/seekor/i,
  ],
  "100g": [
    /\b(?:\d*\.?\d*\s*)?(?:g|gram|grams|G)\b/i,
    /(?:±|\+\-)\s*\d*\.?\d*\s*g/i,
  ],
  L: [
    /\b(?:\d*\.?\d*\s*)?(?:l|L|liter|liters|litre|litres)\b/i,
    /\b\d*\.?\d*\s*(?:l|L|liter|liters|litre|litres)\b/i,
  ],
  mL: [
    /\b(?:\d*\.?\d*\s*)?(?:ml|mL|milliliter|milliliters|millilitre|millilitres)\b/i,
  ],
  piece: [
    /\b(?:\d*\.?\d*\s*)?(?:piece|pieces|pc|pcs|unit|units|biji|bijis|set|sets|sheet|sheets|ekor|ekors)\b/i,
    /\b(?:se|1)(?:biji|unit|set|naskah|pinggan|bungkus|mangkuk|ketul|piring|keping|gelas|batang|paket)\b/i,
  ],
};

const getDisplayUnit = (unit) => {
  const normalized = normalizeUnit(unit);
  switch (normalized) {
    case "kg":
      return "kg";
    case "100g":
      return "100g";
    case "L":
      return "L";
    case "mL":
      return "mL";
    case "piece":
      return "piece";
    default:
      return unit;
  }
};

function AddProductPage() {
  const [productName, setProductName] = useState("");
  const [location, setLocation] = useState(null);
  const [locationDetail, setLocationDetail] = useState("");
  const [unitMeasurement, setUnitMeasurement] = useState("");
  const [price, setPrice] = useState("");
  const [priceAnalysisData, setPriceAnalysisData] = useState([]);
  const [selectedPriceRef, setSelectedPriceRef] = useState(null);
  const [priceSearchTerm, setPriceSearchTerm] = useState("");
  const [showPriceAnalysis, setShowPriceAnalysis] = useState(false);
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] =
    useState(false);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [postPrice, setPostPrice] = useState(0.1);
  const [updatedBalance, setUpdatedBalance] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  useEffect(() => {
    fetchWalletBalance();
    fetchPostPrice();
  }, []);

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    const fetchPriceAnalysis = async () => {
      try {
        const response = await axios.get(
          "https://api-dot-keboon-ai-439616.as.r.appspot.com/api/average-prices"
        );
        setPriceAnalysisData(
          Object.entries(response.data).map(([name, data]) => ({
            name,
            ...data,
          }))
        );
      } catch (error) {
        console.error("Error fetching price analysis:", error);
      }
    };
    fetchPriceAnalysis();
  }, []);

  const checkUnitCompatibility = (selectedUnit) => {
    if (!selectedPriceRef) return true;

    const normalizedRefUnit = normalizeUnit(selectedPriceRef.unit);
    const normalizedSelectedUnit = normalizeUnit(selectedUnit);

    const isWeightUnit = (unit) => unitGroups.weight.includes(unit);
    const isVolumeUnit = (unit) => unitGroups.volume.includes(unit);
    const isPieceUnit = (unit) => unitGroups.piece.includes(unit);

    const areCompatible =
      (isWeightUnit(normalizedRefUnit) &&
        isWeightUnit(normalizedSelectedUnit)) ||
      (isVolumeUnit(normalizedRefUnit) &&
        isVolumeUnit(normalizedSelectedUnit)) ||
      (isPieceUnit(normalizedRefUnit) && isPieceUnit(normalizedSelectedUnit));

    return areCompatible;
  };

  const fetchWalletBalance = async () => {
    try {
      const response = await axios.get("/api/wallet", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setWalletBalance(response.data.balance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      setError("Failed to fetch wallet balance. Please try again.");
    }
  };

  const fetchPostPrice = async () => {
    try {
      const response = await axios.get("/api/post-price");
      setPostPrice(response.data.price);
    } catch (error) {
      console.error("Error fetching post price:", error);
      setError("Failed to fetch post price. Please try again.");
    }
  };

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = [
            position.coords.latitude,
            position.coords.longitude,
          ];
          setLocation(currentLocation);
        },
        (error) => {
          console.error("Error getting location:", error);
          console.log("Falling back to default location (Kuala Lumpur)");
          setLocation(defaultCenter);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Geolocation is not supported. Using default location.");
      setLocation(defaultCenter);
    }
  };

  function MapEvents() {
    const map = useMap();

    useMapEvents({
      click(e) {
        setLocation([e.latlng.lat, e.latlng.lng]);
        map.setView([e.latlng.lat, e.latlng.lng], map.getZoom());
      },
    });

    useEffect(() => {
      if (location) {
        map.setView(location, map.getZoom());
      }
    }, [location, map]);

    return null;
  }

  const handleMarkerDragEnd = (event) => {
    const marker = event.target;
    const position = marker.getLatLng();
    setLocation([position.lat, position.lng]);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const MAX_WIDTH = 800;
          const scaleFactor = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const compressedBase64 = canvas.toDataURL("image/jpeg", 0.8);
          resolve(compressedBase64);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const compressedBase64 = await compressImage(file);
      setImage(compressedBase64);
      setPreviewUrl(compressedBase64);
    }
  };

  const clearForm = () => {
    setProductName("");
    setLocation(null);
    setLocationDetail("");
    setUnitMeasurement("");
    setPrice("");
    setImage(null);
    setPreviewUrl("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    if (
      !productName ||
      !location ||
      !locationDetail ||
      !unitMeasurement ||
      !price ||
      !image
    ) {
      setError(
        "Please fill in all required fields: Name, Location, Image, Unit and Price."
      );
      setIsSubmitting(false);
      return;
    }

    // Check unit compatibility before submission
    if (selectedPriceRef && !checkUnitCompatibility(unitMeasurement)) {
      setError(
        `Invalid unit combination: Cannot compare ${selectedPriceRef.unit} with ${unitMeasurement}`
      );
      setIsSubmitting(false);
      return;
    }

    if (walletBalance < postPrice) {
      setShowInsufficientFundsModal(true);
      setIsSubmitting(false);
      return;
    }

    // Prepare price reference data if a reference is selected
    let priceReferenceData = null;
    if (selectedPriceRef) {
      const convertedPrice = parseFloat(
        formatConvertedPrice(
          parseFloat(price),
          unitMeasurement,
          selectedPriceRef.unit
        )
      );

      // Calculate price status
      const buffer = selectedPriceRef.mean * 0.2;
      const minAcceptable = selectedPriceRef.mean - buffer;
      const maxAcceptable = selectedPriceRef.mean + buffer;
      let status = "within";
      if (convertedPrice < minAcceptable) status = "below";
      if (convertedPrice > maxAcceptable) status = "above";

      priceReferenceData = {
        name: selectedPriceRef.name,
        mean: selectedPriceRef.mean,
        unit: selectedPriceRef.unit,
        min: selectedPriceRef.min,
        max: selectedPriceRef.max,
        convertedPrice: convertedPrice,
        status: status,
      };
    }

    const productData = {
      name: productName,
      price: parseFloat(price),
      unit: unitMeasurement,
      location: {
        lat: location[0],
        lon: location[1],
      },
      locationDetail: locationDetail,
      image: image,
      priceReference: priceReferenceData,
    };

    try {
      const response = await axios.post("/api/products", productData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      console.log("Product added:", response.data);
      setUpdatedBalance(response.data.newBalance);
      setShowSuccessPopup(true);
    } catch (error) {
      console.error("Error adding product:", error);
      setError(
        `Error adding product: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("/main");
  };

  const handleSuccessPopupClose = () => {
    setShowSuccessPopup(false);
    clearForm();
    getUserLocation();
  };

  const handleTopUp = () => {
    navigate("/wallet");
  };

  const handleCancelPost = () => {
    setShowInsufficientFundsModal(false);
  };

  const handlePriceRefSelect = (item) => {
    setSelectedPriceRef(item);
    setShowPriceAnalysis(false);
    setPriceSearchTerm("");

    const normalizedRefUnit = normalizeUnit(item.unit);

    // Check if units are compatible based on groups
    const isWeightUnit = (unit) => unitGroups.weight.includes(unit);
    const isVolumeUnit = (unit) => unitGroups.volume.includes(unit);
    const isPieceUnit = (unit) => unitGroups.piece.includes(unit);

    // If there's already a selected unit measurement, check compatibility
    if (unitMeasurement) {
      const normalizedSelectedUnit = normalizeUnit(unitMeasurement);

      const areCompatible =
        (isWeightUnit(normalizedRefUnit) &&
          isWeightUnit(normalizedSelectedUnit)) ||
        (isVolumeUnit(normalizedRefUnit) &&
          isVolumeUnit(normalizedSelectedUnit)) ||
        (isPieceUnit(normalizedRefUnit) && isPieceUnit(normalizedSelectedUnit));

      if (!areCompatible) {
        alert(`Unit conversion not compatible: Cannot compare ${
          item.unit
        } with ${unitMeasurement}. 
        \nPlease select a compatible unit for comparison:
        \n- Weight units: ${unitGroups.weight.join(", ")}
        \n- Volume units: ${unitGroups.volume.join(", ")}
        \n- Piece units: ${unitGroups.piece.join(", ")}`);
        setUnitMeasurement(""); // Clear the unit measurement
        return;
      }
    }

    // For new selections without existing unit measurement
    if (
      !unitMeasurement &&
      unitOptions.some((option) => option.value === normalizedRefUnit)
    ) {
      setUnitMeasurement(normalizedRefUnit);
    }
  };

  const isPriceWithinRange = (price, reference) => {
    if (!reference || !price) return true;

    // Convert both prices to base units for comparison
    // Inside isPriceWithinRange, update the getBaseAmount function with the same logic:
    const getBaseAmount = (price, unit, targetUnit) => {
      const multiplier = extractNumericValue(unit);
      const normalized = normalizeUnit(unit);
      const normalizedTarget = normalizeUnit(targetUnit);

      // If units are the same, just return the price
      if (normalized === normalizedTarget) {
        return price * multiplier;
      }

      // Weight conversions
      if (unit.toLowerCase().includes("g") || normalized === "kg") {
        switch (true) {
          case normalized === "100g" && normalizedTarget === "kg":
            return price * 10; // Convert from 100g to kg
          case normalized === "kg" && normalizedTarget === "100g":
            return price / 10; // Convert from kg to 100g
          default:
            return price * multiplier;
        }
      }

      // Volume conversions
      if (unit.toLowerCase().includes("l")) {
        switch (true) {
          case normalized === "mL" && normalizedTarget === "L":
            return price * 1000; // Convert from mL to L
          case normalized === "L" && normalizedTarget === "mL":
            return price / 1000; // Convert from L to mL
          default:
            return price * multiplier;
        }
      }

      return price * multiplier;
    };

    const convertedPrice = getBaseAmount(
      price,
      unitMeasurement,
      reference.unit
    );
    const referencePrice = getBaseAmount(
      reference.mean,
      reference.unit,
      reference.unit
    );

    // Calculate acceptable range
    const buffer = referencePrice * 0.2;
    const minAcceptable = referencePrice - buffer;
    const maxAcceptable = referencePrice + buffer;

    console.log({
      originalPrice: price,
      originalUnit: unitMeasurement,
      convertedPrice,
      referenceUnit: reference.unit,
      referencePrice,
      min: minAcceptable,
      max: maxAcceptable,
    });

    return convertedPrice >= minAcceptable && convertedPrice <= maxAcceptable;
  };

  const formatConvertedPrice = (price, fromUnit, toUnit) => {
    const getBaseAmount = (price, unit, targetUnit) => {
      const multiplier = extractNumericValue(unit);
      const normalized = normalizeUnit(unit);
      const normalizedTarget = normalizeUnit(targetUnit);

      // If units are the same, just return the price
      if (normalized === normalizedTarget) {
        return price * multiplier;
      }

      // Weight conversions
      if (unit.toLowerCase().includes("g") || normalized === "kg") {
        switch (true) {
          case normalized === "100g" && normalizedTarget === "kg":
            return price * 10; // Convert from 100g to kg (multiply by 10 since 1kg = 10x100g)
          case normalized === "kg" && normalizedTarget === "100g":
            return price / 10; // Convert from kg to 100g (divide by 10)
          default:
            return price * multiplier;
        }
      }

      // Volume conversions
      if (unit.toLowerCase().includes("l")) {
        switch (true) {
          case normalized === "mL" && normalizedTarget === "L":
            return price * 1000; // Convert from mL to L
          case normalized === "L" && normalizedTarget === "mL":
            return price / 1000; // Convert from L to mL
          default:
            return price * multiplier;
        }
      }

      return price * multiplier;
    };

    const converted = getBaseAmount(price, fromUnit, toUnit);

    // Handle very small numbers to prevent scientific notation
    if (converted < 0.01) {
      return converted.toFixed(4);
    }
    return converted.toFixed(2);
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>Add Product</Subtitle>
      </Header>
      <Container>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <p>Posting a product costs {(postPrice * 100).toFixed(0)} Boons</p>
        <p>Your current balance: {(walletBalance * 100).toFixed(0)} Boons</p>

        {isPreviewMode ? (
          <div>
            <ProductCard>
              <ProductPreviewImage
                src={previewUrl || "/api/placeholder/100/100"}
                alt="Preview"
              />
              <ProductInfo>
                <ProductName>{productName || "Product Name"}</ProductName>
                <ProductPrice>
                  RM{price || "0.00"}/{unitMeasurement || "unit"}
                </ProductPrice>
                <PostedByLink>
                  <FontAwesomeIcon icon={faUser} />
                  Posted by You
                </PostedByLink>
                <LocationLink
                  onClick={() => handleLocationClick(location[0], location[1])}
                >
                  {locationDetail || "Location details"}
                </LocationLink>

                {selectedPriceRef ? (
                  <>
                    <PriceComparisonText
                      status={
                        isPriceWithinRange(parseFloat(price), selectedPriceRef)
                          ? "within"
                          : "above"
                      }
                    >
                      {isPriceWithinRange(parseFloat(price), selectedPriceRef)
                        ? "Market price"
                        : "Above market price"}
                    </PriceComparisonText>
                    <MarketPriceInfo>
                      Market Avg: RM{selectedPriceRef.mean.toFixed(2)}/
                      {selectedPriceRef.unit}
                      <PriceInfoIcon>
                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                        <Tooltip>
                          Market prices are based on average retail prices
                          across Malaysia.
                        </Tooltip>
                      </PriceInfoIcon>
                    </MarketPriceInfo>
                  </>
                ) : (
                  <PriceInfoWrapper>
                    Market price information unavailable before posting
                    <PriceInfoIcon>
                      <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                      <Tooltip>
                        Price analysis will be available after posting
                      </Tooltip>
                    </PriceInfoIcon>
                  </PriceInfoWrapper>
                )}
              </ProductInfo>
            </ProductCard>

            <ButtonContainer>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CancelButton
                type="button"
                onClick={() => setIsPreviewMode(false)}
              >
                <FontAwesomeIcon icon={faEdit} style={{ marginRight: "5px" }} />
                Edit
              </CancelButton>
              <PostButton onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? "Posting..." : "Post"}
              </PostButton>
            </ButtonContainer>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <ImageLabel>
              {previewUrl ? (
                <img
                  src={previewUrl}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "4px",
                  }}
                />
              ) : (
                <UploadIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </UploadIcon>
              )}
              <ImageInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </ImageLabel>

            <PriceSelect>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  type="text"
                  placeholder="Search for price reference (optional)..."
                  value={priceSearchTerm}
                  onChange={(e) => {
                    setPriceSearchTerm(e.target.value);
                    setShowPriceAnalysis(true);
                  }}
                />
                {selectedPriceRef && (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPriceRef(null);
                      setPriceSearchTerm("");
                    }}
                    style={{
                      marginLeft: "10px",
                      padding: "5px 10px",
                      border: "none",
                      borderRadius: "4px",
                      backgroundColor: "#f44336",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    Clear
                  </button>
                )}
              </div>
              {showPriceAnalysis && (
                <PriceDropdown>
                  {priceAnalysisData
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(priceSearchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <PriceOption
                        key={index}
                        onClick={() => handlePriceRefSelect(item)}
                      >
                        {item.name} - RM{item.mean.toFixed(2)}/{item.unit}
                      </PriceOption>
                    ))}
                </PriceDropdown>
              )}
            </PriceSelect>

            {selectedPriceRef && (
              <PriceAnalysisCard
                isWithinRange={isPriceWithinRange(
                  parseFloat(price),
                  selectedPriceRef
                )}
              >
                <p>
                  <strong>Market Price Reference:</strong>{" "}
                  {selectedPriceRef.name}
                </p>
                <p>Your Unit: {unitMeasurement}</p>
                <p>Market Unit: {selectedPriceRef.unit}</p>
                <p>
                  Market Average: RM{selectedPriceRef.mean.toFixed(2)}/
                  {selectedPriceRef.unit}
                </p>
                <p>
                  Market Range: RM{selectedPriceRef.min.toFixed(2)} - RM
                  {selectedPriceRef.max.toFixed(2)}/{selectedPriceRef.unit}
                </p>
                {price && unitMeasurement && (
                  <>
                    <p>
                      Your Price: RM{price}/{unitMeasurement}
                    </p>
                    {unitMeasurement !==
                      normalizeUnit(selectedPriceRef.unit) && (
                      <p>
                        Converted Price: RM
                        {formatConvertedPrice(
                          parseFloat(price),
                          unitMeasurement,
                          normalizeUnit(selectedPriceRef.unit)
                        )}
                      </p>
                    )}
                    <p>
                      Status:{" "}
                      {isPriceWithinRange(parseFloat(price), selectedPriceRef)
                        ? "✅ Price within acceptable range"
                        : "⚠️ Price outside typical range"}
                    </p>
                  </>
                )}
              </PriceAnalysisCard>
            )}

            <Input
              placeholder="Produce Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />

            <MapComponent
              center={location || defaultCenter}
              zoom={13}
              ref={mapRef}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <MapEvents />
              {location && (
                <Marker
                  position={location}
                  icon={locationIcon}
                  draggable={true}
                  eventHandlers={{
                    dragend: handleMarkerDragEnd,
                  }}
                >
                  <Popup>Product location</Popup>
                </Marker>
              )}
            </MapComponent>

            <TextArea
              placeholder="Location Detail"
              value={locationDetail}
              onChange={(e) => setLocationDetail(e.target.value)}
              required
            />

            <Select
              value={unitMeasurement}
              onChange={(e) => {
                const newUnit = e.target.value;
                if (
                  selectedPriceRef &&
                  newUnit &&
                  !checkUnitCompatibility(newUnit)
                ) {
                  alert(`Unit conversion not compatible: Cannot compare ${
                    selectedPriceRef.unit
                  } with ${newUnit}. 
              \nPlease select a compatible unit for comparison:
              \n- Weight units: ${unitGroups.weight.join(", ")}
              \n- Volume units: ${unitGroups.volume.join(", ")}
              \n- Piece units: ${unitGroups.piece.join(", ")}`);
                  return;
                }
                setUnitMeasurement(newUnit);
              }}
              required
            >
              <option value="">Unit Measurement</option>
              {unitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>

            <PriceInput>
              <PriceLabel>Price/ Unit :</PriceLabel>
              <Input
                type="number"
                placeholder="RM"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </PriceInput>

            <p
              style={{ fontSize: "14px", color: "#666", marginBottom: "20px" }}
            >
              Ensure all information is accurate to avoid rejection by the
              system admin.
            </p>

            <ButtonContainer>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CancelButton
                type="button"
                onClick={() => setIsPreviewMode(true)}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginRight: "5px" }} />
                Preview
              </CancelButton>
              <PostButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Posting..." : "Post"}
              </PostButton>
            </ButtonContainer>
          </form>
        )}

        {showSuccessPopup && (
          <SuccessPopup>
            <PopupContent>
              <h2>Post Successful!</h2>
              <p>Your product has been submitted successfully.</p>
              <p>
                {(postPrice * 100).toFixed(0)} Boons has been deducted from your
                wallet.
              </p>
              <p>
                Your new balance is: {(updatedBalance * 100).toFixed(0)} Boons
              </p>
              <p>
                Please wait for admin approval. After approval, you will see the
                product on the main page.
              </p>
              <PopupButton onClick={handleSuccessPopupClose}>OK</PopupButton>
            </PopupContent>
          </SuccessPopup>
        )}

        {showInsufficientFundsModal && (
          <ModalOverlay>
            <ModalContent>
              <h2>Insufficient Funds</h2>
              <p>
                You need {(postPrice * 100).toFixed(0)} Boons to post a product.
                Your current balance is {(walletBalance * 100).toFixed(0)}{" "}
                Boons.
              </p>
              <ModalButton onClick={handleTopUp}>Top Up</ModalButton>
              <ModalButton onClick={handleCancelPost}>Cancel</ModalButton>
            </ModalContent>
          </ModalOverlay>
        )}
      </Container>
      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} />
          </NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon>
            <FontAwesomeIcon icon={faPlus} />
          </NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon>
            <FontAwesomeIcon icon={faWallet} />
          </NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon>
            <FontAwesomeIcon icon={faList} />
          </NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon>
            <FontAwesomeIcon icon={faUser} />
          </NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default AddProductPage;
