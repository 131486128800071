import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Link,
  useNavigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faSearch,
  faHeart,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./icons/keboon-logo-white.png";
import KeboonSupplies from "./KeboonSupplies";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const PostedByLink = styled(Link)`
  color: #377e62;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0;

  &:hover {
    text-decoration: underline;
  }
`;

const InterestedButton = styled.button`
  padding: 5px 10px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377e62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SoldOutTag = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f44336;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const SearchBarContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #377e62;
`;

const SortDropdown = styled.select`
  padding: 10px;
  border: 0px solid #ddd;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #f8f8f8;
  font-size: 16px;
  color: #377e62;
  cursor: pointer;
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #377e62;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.p`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52ae77;
`;

const ProductLocation = styled.p`
  margin: 0 0 5px 0;
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.active ? "#377E62" : "white")};
  color: ${(props) => (props.active ? "white" : "#333")};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377e62;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalInput = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

const TotalPriceDisplay = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #377e62;
  margin-top: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled(Link)`
  padding: 10px 20px;
  background-color: ${(props) => (props.active ? "#377E62" : "white")};
  color: ${(props) => (props.active ? "white" : "#377E62")};
  border: 1px solid #377e62;
  text-decoration: none;
  transition: all 0.3s ease;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &:hover {
    background-color: #52ae77;
    color: white;
  }
`;

const PriceComparisonText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  color: ${(props) =>
    props.status === "above"
      ? "#FF5722" // Red for above market price
      : props.status === "within"
      ? "#4CAF50" // Green for within market price
      : "#4CAF50"}; // Green for below market price
`;

const MarketPriceInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 2px;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  width: max-content;
  max-width: 200px;
  display: none;
`;

const PriceInfoIcon = styled.span`
  position: relative;
  margin-left: 5px;
  cursor: help;

  &:hover ${Tooltip} {
    display: block;
  }
`;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Custom icons for user and product locations
const userIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const productIcon = new L.Icon({
  iconUrl:
    "https://keboon.net/assets/img/icon-outline.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const unitNormalization = {
  kg: [
    /\b(?:\d*\.?\d*\s*)?(?:kg|kgs|kilo|kilos|kilogram|kilograms|Kg|KG)\b/i,
    /±?\s*\d*\.?\d*\s*kg\/seekor/i,
  ],
  "100g": [
    /\b(?:\d*\.?\d*\s*)?(?:g|gram|grams|G)\b/i,
    /(?:±|\+\-)\s*\d*\.?\d*\s*g/i,
  ],
  L: [
    /\b(?:\d*\.?\d*\s*)?(?:l|L|liter|liters|litre|litres)\b/i,
    /\b\d*\.?\d*\s*(?:l|L|liter|liters|litre|litres)\b/i,
  ],
  mL: [
    /\b(?:\d*\.?\d*\s*)?(?:ml|mL|milliliter|milliliters|millilitre|millilitres)\b/i,
  ],
  "10 pieces": [
    /\b(?:\d*\.?\d*\s*)?(?:10 biji|10 pieces|10 pcs|sepuluh biji)\b/i,
  ],
  piece: [
    /\b(?:\d*\.?\d*\s*)?(?:piece|pieces|pc|pcs|unit|units|biji|bijis|set|sets|sheet|sheets|ekor|ekors)\b/i,
    /\b(?:se|1)(?:biji|unit|set|naskah|pinggan|bungkus|mangkuk|ketul|piring|keping|gelas|batang|paket)\b/i,
  ]
};

const baseConversions = {
  kg: {
    base: 1000,
    "100g": 10,
  },
  "100g": {
    base: 100,
    kg: 0.1,
  },
  L: {
    base: 1000,
    mL: 1000,
  },
  mL: {
    base: 1,
    L: 0.001,
  },
  "10 pieces": {
    base: 10,
    piece: 0.1
  },
  piece: {
    base: 1,
    "10 pieces": 10
  }
};

const extractNumericValue = (unitStr) => {
  if (!unitStr) return 1;
  const cleanStr = unitStr.replace(/[±\+\-]/g, "").trim();
  const matches = cleanStr.match(/(\d*\.?\d+)/);
  return matches ? parseFloat(matches[1]) : 1;
};

const normalizeUnit = (unit) => {
  if (!unit) return null;
  const cleanUnit = unit.trim().toLowerCase();
  for (const [normalizedUnit, patterns] of Object.entries(unitNormalization)) {
    for (const pattern of patterns) {
      if (pattern.test(cleanUnit)) {
        return normalizedUnit;
      }
    }
  }
  return unit;
};

const stripNumericPrefix = (unit) => {
  return unit.replace(/^\d+\s*/, "").trim();
};

function MainLoggedIn() {
  const [showWhatsAppPopup, setShowWhatsAppPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 5;
  const [mapCenter, setMapCenter] = useState([3.139003, 101.686855]);
  const [userLocation, setUserLocation] = useState(null);
  const [sortBy, setSortBy] = useState("distance");
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [isInterestLoading, setIsInterestLoading] = useState(false);
  const [loadingProductId, setLoadingProductId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [interestedProductId, setInterestedProductId] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const location = useLocation();

  const fetchApiConfig = useCallback(async () => {
    try {
      const response = await axios.get("/api/config");
      setApiUrl(response.data.backendUrl);
    } catch (error) {
      console.error("Error fetching API config:", error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!apiUrl) return;
    setIsLoading(true);
    try {
      const [productsResponse, pricesResponse] = await Promise.all([
        axios.get(`${apiUrl}/api/products`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }),
        axios.get(
          "https://api-dot-keboon-ai-439616.as.r.appspot.com/api/average-prices"
        ),
      ]);

      const priceAnalysisData = pricesResponse.data;

      const getConvertedPrice = (price, fromUnit, toUnit) => {
        const normalized = {
          from: normalizeUnit(fromUnit),
          to: normalizeUnit(toUnit)
        };
      
        // Handle piece to 10 pieces conversion for eggs
        if (normalized.from === "piece" && normalized.to === "10 pieces") {
          return price * 10; // 0.8/piece -> 8/10pieces
        }
      
        // Handle 10 pieces to piece conversion
        if (normalized.from === "10 pieces" && normalized.to === "piece") {
          return price / 10; // 8/10pieces -> 0.8/piece
        }
      
        // Handle 100g to kg conversion
        if (normalized.from === "100g" && normalized.to === "kg") {
          return price * 10; // 0.7/100g -> 7/kg
        }
      
        // Handle kg to 100g conversion
        if (normalized.from === "kg" && normalized.to === "100g") {
          return price / 10; // 7/kg -> 0.7/100g
        }
      
        // Handle L to mL conversion
        if (normalized.from === "L" && normalized.to === "mL") {
          return price * 1000; // 0.7/L -> 700/mL
        }
      
        // Handle mL to L conversion
        if (normalized.from === "mL" && normalized.to === "L") {
          return price / 1000; // 700/mL -> 0.7/L
        }
      
        // Same units or unsupported conversion
        return price;
      };

      const productsWithAnalysis = productsResponse.data.map((product) => {
        // If product has priceReference, use it without additional analysis
        if (product.priceReference) {
          return {
            ...product,
            priceAnalysis: {
              status: product.priceReference.status,
              meanPrice: product.priceReference.meanPrice,
              minPrice: product.priceReference.min,
              maxPrice: product.priceReference.max,
              referenceUnit: product.priceReference.unit,
              convertedPrice: product.priceReference.convertedPrice,
              matched: true,
            },
          };
        }

        // If product explicitly has null priceReference, don't perform analysis
        if (
          product.hasOwnProperty("priceReference") &&
          product.priceReference === null
        ) {
          return {
            ...product,
            priceAnalysis: null,
          };
        }

        let analysis = Object.entries(priceAnalysisData).find(
          ([name, data]) => name.toLowerCase() === product.name.toLowerCase()
        );

        if (!analysis) {
          analysis = Object.entries(priceAnalysisData).find(
            ([name, data]) =>
              product.name.toLowerCase().includes(name.toLowerCase()) ||
              name.toLowerCase().includes(product.name.toLowerCase())
          );
        }

        if (analysis) {
          const [, priceData] = analysis;
        
          // Convert product price to reference unit for comparison
          const convertedPrice = getConvertedPrice(
            product.price,
            product.unit,
            priceData.unit
          );
        
          // Calculate the percentage difference from mean price
          const percentageDiff = Math.abs((convertedPrice - priceData.mean) / priceData.mean);
          
          // Price is considered "within" market price if it's within 20% of the mean
          const status = 
            percentageDiff <= 0.2 
              ? "within"
              : convertedPrice < priceData.mean 
                ? "below" 
                : "above";
        
          return {
            ...product,
            priceAnalysis: {
              status,
              meanPrice: priceData.mean,
              minPrice: priceData.min,
              maxPrice: priceData.max,
              referenceUnit: priceData.unit,
              convertedPrice,
              matched: true,
            },
          };
        }

        return {
          ...product,
          priceAnalysis: null,
        };
      });

      setProducts(productsWithAnalysis);

      if (userLocation) {
        setMapCenter([userLocation.latitude, userLocation.longitude]);
      } else if (
        productsWithAnalysis.length > 0 &&
        productsWithAnalysis[0].location
      ) {
        setMapCenter([
          productsWithAnalysis[0].location.lat,
          productsWithAnalysis[0].location.lon,
        ]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  }, [apiUrl, userLocation, navigate]);

  useEffect(() => {
    fetchApiConfig();
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken.userId);
    }
  }, [fetchApiConfig]);

  useEffect(() => {
    if (apiUrl) {
      fetchProducts();
      const intervalId = setInterval(fetchProducts, 1800000);
      return () => clearInterval(intervalId);
    }
  }, [apiUrl, fetchProducts]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const filteredAndSortedProducts = products
    .filter((product) =>
      product.name.toLowerCase().includes(search.toLowerCase())
    )
    .map((product) => ({
      ...product,
      distance:
        userLocation && product.location
          ? calculateDistance(
              userLocation.latitude,
              userLocation.longitude,
              product.location.lat,
              product.location.lon
            )
          : Infinity,
    }))
    .sort((a, b) => {
      if (sortBy === "distance") {
        return a.distance - b.distance;
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const handleInterested = (product) => {
    setInterestedProductId(product._id);
    setSelectedProduct(product);
    setQuantity("");
    setTotalPrice(0);
    setShowModal(true);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);
    if (selectedProduct && newQuantity !== "") {
      const calculatedTotal = selectedProduct.price * parseInt(newQuantity);
      setTotalPrice(isNaN(calculatedTotal) ? 0 : calculatedTotal);
    } else {
      setTotalPrice(0);
    }
  };

  const handleSubmitInterest = async () => {
    if (!quantity.trim()) {
      alert("Please enter a quantity.");
      return;
    }

    const quantityNum = parseInt(quantity);
    if (isNaN(quantityNum) || quantityNum <= 0) {
      alert("Please enter a valid quantity.");
      return;
    }

    setIsInterestLoading(true);
    setLoadingProductId(interestedProductId);
    try {
      const payload = {
        quantity: quantityNum,
        totalPrice: totalPrice,
      };
      console.log("Sending interest payload:", payload);

      const response = await axios.post(
        `${apiUrl}/api/products/${interestedProductId}/interest`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      console.log("Interest submission response:", response.data);

      // If the user doesn't have name or mobile number, redirect to profile
      if (response.data.missingProfile) {
        alert(
          "Please set up your name and mobile number in your profile before expressing interest."
        );
        navigate("/profile");
        return;
      }

      // If successful, show WhatsApp popup
      if (response.data.sellerPhone) {
        setSelectedProduct((prev) => ({
          ...prev,
          sellerPhone: response.data.sellerPhone,
        }));
        setShowWhatsAppPopup(true);
      } else {
        alert(
          response.data.message ||
            "You have expressed interest in this product. The seller will be notified."
        );
        setShowModal(false);
        setQuantity("");
        setTotalPrice(0);
      }
    } catch (error) {
      console.error("Error expressing interest:", error);
      if (error.response && error.response.data) {
        if (
          error.response.status === 400 &&
          error.response.data.message.includes("name and mobile number")
        ) {
          alert(
            "Please set up your name and mobile number in your profile before expressing interest."
          );
          navigate("/profile");
        } else {
          alert(
            error.response.data.message ||
              "Failed to express interest. Please try again."
          );
        }
      } else {
        alert(
          "An error occurred while sending your request. Please try again."
        );
      }
    } finally {
      setIsInterestLoading(false);
      setLoadingProductId(null);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredAndSortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach((product) => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  const pageCount = Math.ceil(
    filteredAndSortedProducts.length / productsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
      </Header>
      <MainContainer>
        <TabContainer>
          <Tab to="/main" active={location.pathname === "/main"}>
            Find Keboon
          </Tab>
          <Tab
            to="/main/supplies"
            active={location.pathname === "/main/supplies"}
          >
            Keboon Supplies
          </Tab>
        </TabContainer>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <SearchBarContainer>
                  <SearchBarWrapper>
                    <SearchBar
                      type="text"
                      placeholder="Search products"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <SearchIcon>
                      <FontAwesomeIcon icon={faSearch} />
                    </SearchIcon>
                  </SearchBarWrapper>
                  <SortDropdown
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value="distance">Distance</option>
                    <option value="date">Date</option>
                  </SortDropdown>
                </SearchBarContainer>

                <MapComponent center={mapCenter} zoom={16}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <ChangeMapView center={mapCenter} />
                  {userLocation && (
                    <Marker
                      position={[userLocation.latitude, userLocation.longitude]}
                      icon={userIcon}
                    >
                      <Popup>You are here</Popup>
                    </Marker>
                  )}
                  {filteredAndSortedProducts.map(
                    (product) =>
                      !product.soldOut &&
                      product.location && (
                        <Marker
                          key={product._id}
                          position={[
                            product.location.lat,
                            product.location.lon,
                          ]}
                          icon={productIcon}
                        >
                          <Popup>
                            <strong>{product.name}</strong>
                            <br />
                            Price: RM{product.price}/{product.unit}
                            <br />
                            Distance: {product.distance.toFixed(2)} km
                          </Popup>
                        </Marker>
                      )
                  )}
                </MapComponent>

                {isLoading ? (
                  <LoadingMessage>Loading products...</LoadingMessage>
                ) : (
                  Object.entries(groupedProducts).map(
                    ([date, dateProducts]) => (
                      <DateGroup key={date}>
                        <DateHeader>{formatDate(date)}</DateHeader>
                        {dateProducts.map((product) => (
                          <ProductCard key={product._id}>
                            <ProductImage
                              src={product.image}
                              alt={product.name}
                            />
                            <ProductInfo>
                              <ProductName>{product.name}</ProductName>
                              <ProductPrice>
                                RM{product.price}/{product.unit}
                              </ProductPrice>
                              <PostedByLink
                                to={`/shared-keboon/${product.user?._id}`}
                              >
                                <FontAwesomeIcon icon={faUser} />
                                Posted by {product.user?.name || "Keboon User"}
                              </PostedByLink>
                              {!product.soldOut && (
                                <>
                                  <ProductLocation
                                    onClick={() =>
                                      handleLocationClick(
                                        product.location.lat,
                                        product.location.lon
                                      )
                                    }
                                  >
                                    {product.locationDetail}
                                  </ProductLocation>
                                  {product.user !== userId && (
                                    <InterestedButton
                                      onClick={() => handleInterested(product)}
                                      disabled={
                                        isInterestLoading &&
                                        loadingProductId === product._id
                                      }
                                    >
                                      {isInterestLoading &&
                                      loadingProductId === product._id ? (
                                        <>
                                          <SpinnerIcon icon={faSpinner} spin />
                                          Loading...
                                        </>
                                      ) : (
                                        <>
                                          <FontAwesomeIcon icon={faHeart} />{" "}
                                          Interested
                                        </>
                                      )}
                                    </InterestedButton>
                                  )}
                                </>
                              )}
                              {product.distance !== Infinity && (
                                <DistanceTag>
                                  {product.distance.toFixed(2)} km
                                </DistanceTag>
                              )}
                              {product.priceAnalysis && (
                                <>
                                  <PriceComparisonText
                                    status={product.priceAnalysis.status}
                                  >
                                    {product.priceAnalysis.status === "below" &&
                                      "Below market price"}
                                    {product.priceAnalysis.status ===
                                      "within" && "Market price"}
                                    {product.priceAnalysis.status === "above" &&
                                      "Above market price"}
                                  </PriceComparisonText>
                                  <MarketPriceInfo>
                                    Market Avg: RM
                                    {product.priceAnalysis.meanPrice.toFixed(2)}
                                    /{product.priceAnalysis.referenceUnit}
                                    {normalizeUnit(product.unit) !==
                                      normalizeUnit(
                                        product.priceAnalysis.referenceUnit
                                      ) && (
                                      <div>
                                        Your price (converted): RM
                                        {product.priceAnalysis.convertedPrice.toFixed(
                                          2
                                        )}
                                        /{product.priceAnalysis.referenceUnit}
                                      </div>
                                    )}
                                    <PriceInfoIcon>
                                      <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        size="sm"
                                      />
                                      <Tooltip>
                                        Market prices are based on average
                                        retail prices across Malaysia.
                                        {normalizeUnit(product.unit) !==
                                          normalizeUnit(
                                            product.priceAnalysis.referenceUnit
                                          ) &&
                                          ` Price has been converted from ${product.unit} to ${product.priceAnalysis.referenceUnit} for comparison.`}
                                      </Tooltip>
                                    </PriceInfoIcon>
                                  </MarketPriceInfo>
                                </>
                              )}
                              {!product.priceAnalysis && (
                                <MarketPriceInfo>
                                  <em>No market price reference available</em>
                                </MarketPriceInfo>
                              )}
                            </ProductInfo>
                            {product.soldOut && (
                              <SoldOutTag>Sold Out</SoldOutTag>
                            )}
                          </ProductCard>
                        ))}
                      </DateGroup>
                    )
                  )
                )}

                {showModal && (
                  <Modal>
                    <ModalContent>
                      <h3>Express Interest</h3>
                      <ModalInput
                        type="number"
                        placeholder="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        min="1"
                      />
                      <p>
                        Product Price: RM{selectedProduct?.price}/
                        {selectedProduct?.unit}
                      </p>
                      <TotalPriceDisplay>
                        Total Price: RM{totalPrice.toFixed(2)}
                      </TotalPriceDisplay>
                      <ModalButton
                        onClick={handleSubmitInterest}
                        disabled={isInterestLoading}
                      >
                        {isInterestLoading ? "Submitting..." : "Submit"}
                      </ModalButton>
                      <ModalButton onClick={() => setShowModal(false)}>
                        Cancel
                      </ModalButton>
                    </ModalContent>
                  </Modal>
                )}

                {showWhatsAppPopup && (
                  <Modal>
                    <ModalContent>
                      <h3>Contact Seller via WhatsApp</h3>
                      <p>Would you like to contact the seller on WhatsApp?</p>
                      <ModalButton
                        onClick={() => {
                          const message = encodeURIComponent(
                            `Hi! I'm interested in your Keboon product.\n\n` +
                              `I would like to order:\n` +
                              `Product: ${selectedProduct.name}\n` +
                              `Quantity: ${quantity} ${selectedProduct.unit}\n` +
                              `Total Price: RM${totalPrice.toFixed(2)}\n\n` +
                              `Is this still available?`
                          );

                          const formattedPhone =
                            selectedProduct.sellerPhone.replace(/\D/g, "");
                          const phoneWithCountryCode =
                            formattedPhone.startsWith("60")
                              ? formattedPhone
                              : "60" + formattedPhone;

                          window.open(
                            `https://wa.me/${phoneWithCountryCode}?text=${message}`,
                            "_blank"
                          );
                          setShowWhatsAppPopup(false);
                          setShowModal(false);
                        }}
                      >
                        Yes
                      </ModalButton>
                      <ModalButton
                        onClick={() => {
                          setShowWhatsAppPopup(false);
                          setShowModal(false);
                        }}
                      >
                        No
                      </ModalButton>
                    </ModalContent>
                  </Modal>
                )}

                <PaginationContainer>
                  {[...Array(pageCount)].map((_, i) => (
                    <PageButton
                      key={i + 1}
                      onClick={() => handlePageChange(i + 1)}
                      active={currentPage === i + 1}
                    >
                      {i + 1}
                    </PageButton>
                  ))}
                </PaginationContainer>
              </>
            }
          />
          <Route path="/supplies" element={<KeboonSupplies />} />
        </Routes>
      </MainContainer>

      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} />
          </NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon>
            <FontAwesomeIcon icon={faPlus} />
          </NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon>
            <FontAwesomeIcon icon={faWallet} />
          </NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon>
            <FontAwesomeIcon icon={faList} />
          </NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon>
            <FontAwesomeIcon icon={faUser} />
          </NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default MainLoggedIn;
