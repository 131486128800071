import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSpinner,
  faBank,
  faInfoCircle,
  faTrash,
  faHeart,
  faEdit,
  faUpload,
  faWallet,
  faExclamationTriangle,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Section = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: #377e62;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #377e62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:disabled {
    background-color: #cccccc;
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  background: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
`;

const SuccessMessage = styled.div`
  color: #4caf50;
  background: #e8f5e9;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
`;

const TotalProgress = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
`;

const Progress = styled.div`
  width: ${(props) => props.$percentage}%;
  height: 100%;
  background-color: #377e62;
  transition: width 0.3s ease;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
`;

const CampaignForm = ({ onCreate, walletBalance, setError }) => {
  const [campaign, setCampaign] = useState({
    title: "",
    description: "",
    targetAmount: "",
    duration: "", // Added duration field
    benefits: [""], // Initialize with one empty benefit
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBenefitChange = (index, value) => {
    const newBenefits = [...campaign.benefits];
    newBenefits[index] = value;
    setCampaign({ ...campaign, benefits: newBenefits });
  };

  const addBenefit = () => {
    setCampaign({ ...campaign, benefits: [...campaign.benefits, ""] });
  };

  const removeBenefit = (index) => {
    const newBenefits = campaign.benefits.filter((_, i) => i !== index);
    setCampaign({ ...campaign, benefits: newBenefits });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(""); // Clear any existing errors

    try {
      // Validate inputs
      if (!campaign.title.trim()) {
        throw new Error("Campaign title is required");
      }
      if (!campaign.description.trim()) {
        throw new Error("Campaign description is required");
      }
      if (!campaign.targetAmount || parseFloat(campaign.targetAmount) <= 0) {
        throw new Error("Please enter a valid target amount");
      }
      if (
        !campaign.duration ||
        parseInt(campaign.duration) <= 0 ||
        parseInt(campaign.duration) > 90
      ) {
        throw new Error("Please enter a valid duration between 1 and 90 days");
      }

      const targetAmount = parseFloat(campaign.targetAmount);
      const platformFeeRM = targetAmount * 0.05; // 5% in RM
      const platformFeeBoons = Math.round(platformFeeRM * 100); // Convert to Boons (1 RM = 100 Boons)

      // Log values for debugging
      console.log("Campaign Creation Data:", {
        campaignDetails: campaign,
        walletBalance,
        platformFeeBoons,
        platformFeeRM,
      });

      if (platformFeeBoons > walletBalance) {
        throw new Error(
          `Insufficient funds for platform fee (${platformFeeBoons} Boons / RM${platformFeeRM.toFixed(
            2
          )}). Please top up your wallet to continue.`
        );
      }

      // First check bank details
      try {
        const bankDetailsResponse = await axios.get("/api/user/bank-details", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Bank details:", bankDetailsResponse.data);

        if (
          !bankDetailsResponse.data ||
          !bankDetailsResponse.data.bankName?.trim() ||
          !bankDetailsResponse.data.accountNumber?.trim() ||
          !bankDetailsResponse.data.accountHolder?.trim()
        ) {
          throw new Error(
            "Please complete your bank details in your profile before creating a campaign"
          );
        }
      } catch (err) {
        throw new Error(
          "Please add your bank details in your profile before creating a campaign"
        );
      }

      // Filter out empty benefits
      const filteredBenefits = campaign.benefits.filter(
        (benefit) => benefit.trim() !== ""
      );

      // Prepare campaign data
      const campaignData = {
        title: campaign.title.trim(),
        description: campaign.description.trim(),
        targetAmount: targetAmount,
        benefits: filteredBenefits,
      };

      console.log("Sending campaign data:", campaignData);

      const response = await axios.post("/api/campaigns", campaignData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Campaign creation response:", response.data);

      onCreate();
      setCampaign({
        title: "",
        description: "",
        targetAmount: "",
        duration: "",
        benefits: [""],
      });
    } catch (err) {
      console.error("Campaign creation error:", err);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to create campaign";
      console.error("Error details:", {
        status: err.response?.status,
        data: err.response?.data,
        message: errorMessage,
      });
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section>
      <Title>Create New Campaign</Title>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Campaign Title"
          value={campaign.title}
          onChange={(e) => setCampaign({ ...campaign, title: e.target.value })}
          required
        />
        <TextArea
          placeholder="Campaign Description"
          value={campaign.description}
          onChange={(e) =>
            setCampaign({ ...campaign, description: e.target.value })
          }
          required
        />
        <Input
          type="number"
          placeholder="Target Amount (RM)"
          value={campaign.targetAmount}
          onChange={(e) =>
            setCampaign({ ...campaign, targetAmount: e.target.value })
          }
          required
          min="1"
          step="0.01"
        />
        <Input
          type="number"
          placeholder="Campaign Duration (days)"
          value={campaign.duration}
          onChange={(e) =>
            setCampaign({ ...campaign, duration: e.target.value })
          }
          required
          min="1"
          max="90"
        />

        <div style={{ marginBottom: "15px" }}>
          <h4 style={{ color: "#377e62", marginBottom: "10px" }}>
            Funder Benefits
          </h4>
          {campaign.benefits.map((benefit, index) => (
            <div
              key={index}
              style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
            >
              <Input
                value={benefit}
                onChange={(e) => handleBenefitChange(index, e.target.value)}
                placeholder={`Benefit ${index + 1}`}
                style={{ marginBottom: 0 }}
                required={index === 0} // First benefit is required
              />
              {campaign.benefits.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeBenefit(index)}
                  style={{
                    background: "#f44336",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    padding: "0 10px",
                    cursor: "pointer",
                  }}
                >
                  ×
                </button>
              )}
            </div>
          ))}
          <Button
            type="button"
            onClick={addBenefit}
            style={{ backgroundColor: "#4caf50", marginTop: "10px" }}
          >
            + Add Benefit
          </Button>
        </div>

        {/* Wallet Balance Display */}
        <div
          style={{
            marginBottom: "15px",
            color: "#666",
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "4px",
          }}
        >
          <FontAwesomeIcon icon={faWallet} /> Current Wallet Balance:{" "}
          {(walletBalance * 100).toLocaleString()} Boons (RM
          {walletBalance.toFixed(2)})
        </div>

        {/* Platform Fee Calculation */}
        {campaign.targetAmount && (
          <div
            style={{
              marginBottom: "15px",
              backgroundColor: "#e8f5e9",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #81c784",
            }}
          >
            <div style={{ color: "#2e7d32", marginBottom: "5px" }}>
              <FontAwesomeIcon icon={faInfoCircle} /> Platform Fee (5%):
            </div>
            <div style={{ color: "#1b5e20" }}>
              {Math.round(
                parseFloat(campaign.targetAmount) * 0.05 * 100
              ).toLocaleString()}{" "}
              Boons / RM{(parseFloat(campaign.targetAmount) * 0.05).toFixed(2)}
            </div>
            {Math.round(parseFloat(campaign.targetAmount) * 0.05 * 100) >
              walletBalance * 100 && (
              <div
                style={{
                  color: "#d32f2f",
                  marginTop: "5px",
                  fontSize: "0.9em",
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} /> Insufficient
                balance for platform fee
              </div>
            )}
          </div>
        )}

        {/* Create Button */}
        <Button
          type="submit"
          disabled={
            isSubmitting ||
            (campaign.targetAmount &&
              Math.round(parseFloat(campaign.targetAmount) * 0.05) >
                walletBalance / 100)
          }
          style={{
            width: "100%",
            opacity:
              isSubmitting ||
              (campaign.targetAmount &&
                Math.round(parseFloat(campaign.targetAmount) * 0.05) >
                  walletBalance / 100)
                ? 0.7
                : 1,
          }}
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin /> Creating...
            </>
          ) : campaign.targetAmount &&
            Math.round(parseFloat(campaign.targetAmount) * 0.05) >
              walletBalance * 100 ? (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} /> Insufficient
              Balance
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPlus} /> Create Campaign
            </>
          )}
        </Button>
      </form>
    </Section>
  );
};

const CampaignList = ({
  campaigns,
  isOwner,
  onDelete,
  onDonate,
  onSubmitProof,
}) => {
  const navigate = useNavigate();

  const isExpired = (campaign) => {
    const createdDate = new Date(campaign.createdAt);
    const endDate = new Date(
      createdDate.getTime() + campaign.duration * 24 * 60 * 60 * 1000
    );
    return new Date() > endDate;
  };

  // Sort campaigns: active first (newest to oldest), then completed (newest to oldest)
  const sortedCampaigns = [...campaigns].sort((a, b) => {
    if (a.status === b.status) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    return a.status === "active" ? -1 : 1;
  });

  const handleDonateClick = (campaign) => {
    if (campaign.status === "completed") {
      alert(
        "This campaign is already completed and cannot receive more donations."
      );
      return;
    }

    if (isExpired(campaign)) {
      alert("This campaign has ended and cannot receive more donations.");
      return;
    }

    const token = localStorage.getItem("authToken");
    if (!token) {
      if (
        window.confirm(
          "Please log in or sign up to support this campaign. Would you like to sign up now?"
        )
      ) {
        navigate("/signup");
      }
      return;
    }
    onDonate(campaign);
  };

  const handleSubmitProof = async (campaign) => {
    try {
      // Get user and bank details first
      const userResponse = await axios.get("/api/user/profile", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      const bankResponse = await axios.get("/api/user/bank-details", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      const user = userResponse.data;
      const bankDetails = bankResponse.data;

      const mailtoLink =
        `mailto:keboon@keboon.net` +
        `?subject=Campaign Proof Submission - ${campaign.title} - ${campaign._id}` +
        `&body=Campaign Creator Information:%0A%0A` +
        `Name: ${user.name}%0A` +
        `Contact: ${user.mobileNumber}%0A` +
        `Email: ${user.email}%0A%0A` +
        `Bank Details:%0A` +
        `Bank Name: ${bankDetails.bankName}%0A` +
        `Account Number: ${bankDetails.accountNumber}%0A` +
        `Account Holder: ${bankDetails.accountHolder}%0A%0A` +
        `Campaign Information:%0A` +
        `Total Funds Received: RM${campaign.currentAmount.toFixed(2)}%0A` +
        `Minimum Required Spending (80%): RM${(
          campaign.currentAmount * 0.8
        ).toFixed(2)}%0A%0A` +
        `Please attach:%0A` +
        `1. Receipts showing at least 80% fund usage%0A` +
        `2. Photos of results/completed work%0A` +
        `3. Any additional supporting documents%0A%0A` +
        `I hereby declare that all information and documents submitted are true and accurate.`;

      window.location.href = mailtoLink;

      // Update campaign status to completed
      await axios.post(
        `/api/campaigns/${campaign._id}/complete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      window.location.reload(); // Refresh the page to show updated status
    } catch (error) {
      console.error("Error handling proof submission:", error);
      alert("Error processing your request. Please try again.");
    }
  };

  return (
    <Section>
      <Title>Campaigns</Title>

      {/* Total Progress Bar */}
      {campaigns.length > 0 && (
        <TotalProgress>
          <h3 style={{ marginBottom: "10px" }}>Overall Campaign Progress</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <span>
              Total Raised: RM
              {campaigns
                .reduce((acc, c) => acc + c.currentAmount, 0)
                .toFixed(2)}
            </span>
            <span>
              Total Target: RM
              {campaigns.reduce((acc, c) => acc + c.targetAmount, 0).toFixed(2)}
            </span>
          </div>
          <ProgressBar>
            <Progress
              $percentage={
                (campaigns.reduce((acc, c) => acc + c.currentAmount, 0) /
                  campaigns.reduce((acc, c) => acc + c.targetAmount, 0)) *
                100
              }
            />
          </ProgressBar>
        </TotalProgress>
      )}

      {sortedCampaigns.map((campaign) => (
        <div
          key={campaign._id}
          style={{
            marginBottom: "20px",
            padding: "15px",
            border: "1px solid #ddd",
            borderRadius: "4px",
            backgroundColor:
              campaign.status === "completed" ? "#f5f5f5" : "white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{campaign.title}</h3>
            {campaign.status === "completed" && (
              <span
                style={{
                  backgroundColor: "#4caf50",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  fontSize: "12px",
                }}
              >
                Completed
              </span>
            )}
          </div>

          <p>{campaign.description}</p>

          {/* Duration Display */}
          <div
            style={{
              marginTop: "10px",
              backgroundColor: "#f8f9fa",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ marginRight: "8px" }}
                />
                Duration: {campaign.duration} days
              </span>
              {campaign.status === "active" && (
                <span
                  style={{
                    backgroundColor: "#e3f2fd",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    fontSize: "0.9em",
                    color: "#1976d2",
                  }}
                >
                  {(() => {
                    const createdDate = new Date(campaign.createdAt);
                    const endDate = new Date(
                      createdDate.getTime() +
                        campaign.duration * 24 * 60 * 60 * 1000
                    );
                    const now = new Date();
                    const daysLeft = Math.ceil(
                      (endDate - now) / (1000 * 60 * 60 * 24)
                    );
                    return daysLeft > 0 ? `${daysLeft} days left` : "Ended";
                  })()}
                </span>
              )}
            </div>
          </div>

          {campaign.benefits && campaign.benefits.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <h4 style={{ color: "#377e62", marginBottom: "5px" }}>
                Funder Benefits:
              </h4>
              <ul style={{ marginLeft: "20px" }}>
                {campaign.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
          )}

          <div style={{ marginTop: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <span>Raised: RM{campaign.currentAmount.toFixed(2)}</span>
              <span>Target: RM{campaign.targetAmount.toFixed(2)}</span>
            </div>
            <ProgressBar>
              <Progress
                $percentage={
                  (campaign.currentAmount / campaign.targetAmount) * 100
                }
              />
            </ProgressBar>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {isOwner ? (
              <>
                {campaign.status === "active" &&
                  campaign.currentAmount > 0 &&
                  !isExpired(campaign) && (
                    <Button
                      onClick={() => handleSubmitProof(campaign)}
                      style={{ backgroundColor: "#4caf50" }}
                    >
                      <FontAwesomeIcon icon={faUpload} /> Submit Proof
                    </Button>
                  )}
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this campaign?"
                      )
                    ) {
                      onDelete(campaign._id);
                    }
                  }}
                  style={{ backgroundColor: "#f44336" }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </>
            ) : (
              campaign.status === "active" &&
              !isExpired(campaign) && (
                <Button onClick={() => handleDonateClick(campaign)}>
                  <FontAwesomeIcon icon={faHeart} /> Support
                </Button>
              )
            )}
          </div>
        </div>
      ))}

      {campaigns.length === 0 && (
        <p style={{ textAlign: "center", color: "#666" }}>No campaigns yet.</p>
      )}
    </Section>
  );
};

const FundraiseTab = ({ userId }) => {
  const [isOwner, setIsOwner] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [showDonateModal, setShowDonateModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [donationAmount, setDonationAmount] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      setIsOwner(decoded.userId === userId);
    }
    if (userId) {
      // Only fetch if userId is available
      fetchData();
    }
  }, [userId]); // Add userId as dependency

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(""); // Clear any existing errors

      // Get the auth token
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No auth token found");
        setError("Authentication required");
        return;
      }

      // Make the API call with proper headers
      const [campaignsRes, walletRes] = await Promise.all([
        axios.get(`/api/campaigns/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.get("/api/wallet", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      console.log("Fetched campaigns:", campaignsRes.data);
      setCampaigns(campaignsRes.data);
      setWalletBalance(walletRes.data.balance);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(
        err.response?.data?.message || "Failed to load data. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      if (!campaignId) {
        console.error("No campaign ID provided for deletion");
        setError("Unable to delete campaign: Invalid ID");
        return;
      }

      console.log("Deleting campaign with ID:", campaignId);

      await axios.delete(`/api/campaigns/${campaignId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      await fetchData();
      setSuccess("Campaign deleted successfully");
    } catch (err) {
      console.error("Delete campaign error:", err);
      setError(
        "Failed to delete campaign: " +
          (err.response?.data?.message || err.message)
      );
    }
  };

  const handleDonate = async () => {
    try {
      const amount = parseFloat(donationAmount);
      if (isNaN(amount) || amount <= 0) {
        setError("Please enter a valid amount");
        return;
      }

      // Add minimum donation check
      if (amount < 5) {
        setError("Minimum donation amount is RM5 (500 Boons)");
        return;
      }

      if (walletBalance < amount) {
        setError("Insufficient wallet balance");
        return;
      }

      await axios.post(`/api/campaigns/${selectedCampaign._id}/donate`, {
        amount: amount,
      });

      await fetchData();
      setShowDonateModal(false);
      setDonationAmount("");
      setSuccess("Donation successful!");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to process donation");
    }
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      {campaigns.length > 0 && (
        <TotalProgress>
          <h2>Total Fundraising Progress</h2>
          <ProgressBar>
            <Progress
              $percentage={
                (campaigns.reduce((acc, c) => acc + c.currentAmount, 0) /
                  campaigns.reduce((acc, c) => acc + c.targetAmount, 0)) *
                100
              }
            />
          </ProgressBar>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Raised: RM
              {campaigns
                .reduce((acc, c) => acc + c.currentAmount, 0)
                .toFixed(2)}
            </span>
            <span>
              Target: RM
              {campaigns.reduce((acc, c) => acc + c.targetAmount, 0).toFixed(2)}
            </span>
          </div>
        </TotalProgress>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}

      {isOwner && (
        <CampaignForm
          onCreate={fetchData}
          walletBalance={walletBalance}
          setError={setError}
        />
      )}

      <CampaignList
        campaigns={campaigns}
        isOwner={isOwner}
        onDelete={handleDeleteCampaign}
        onDonate={(campaign) => {
          setSelectedCampaign(campaign);
          setShowDonateModal(true);
        }}
      />

      {showDonateModal && (
        <Modal onClick={() => setShowDonateModal(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>Support this Campaign</h3>
            <Input
              type="number"
              placeholder="Enter amount (RM) - Minimum RM5"
              value={donationAmount}
              onChange={(e) => setDonationAmount(e.target.value)}
              min="5" // Add minimum attribute
              step="0.1" // Allow decimal values
            />
            <div style={{ color: "#666", margin: "10px 0" }}>
              Your wallet balance: {walletBalance} Boons (RM
              {walletBalance.toFixed(2)})
            </div>
            <Button onClick={handleDonate}>
              <FontAwesomeIcon icon={faHeart} /> Donate
            </Button>
            <Button
              onClick={() => setShowDonateModal(false)}
              style={{ backgroundColor: "#666" }}
            >
              Cancel
            </Button>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default FundraiseTab;
