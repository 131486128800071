import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from "../pages/icons/keboon-writing.png";

const LoginContainer = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 85%;
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
  color: #2c3e50;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #377e62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
`;

const ForgotPassword = styled.a`
  color: #377e62;
  text-decoration: none;
  margin-bottom: 15px;
  width: 90%;
  text-align: center;
  cursor: pointer;
`;

const SignUpLink = styled.p`
  text-align: center;
  margin-top: 20px;
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const Message = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

function LoginForm({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const fullUrl = `${window.location.origin}/api/config`;
        console.log("Fetching config from:", fullUrl);
        const response = await axios.get(fullUrl);
        console.log("Config response:", response.data);
        if (response.data && response.data.backendUrl) {
          setApiUrl(response.data.backendUrl);
        } else {
          throw new Error("Invalid config response");
        }
      } catch (error) {
        console.error("Error fetching API config:", error);
        setError(
          "Unable to connect to the server. Please check your network connection and try again."
        );
      }
    };

    // Check for existing login session
    const verifySession = async () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        try {
          // Set axios default headers
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          // Verify token validity with the backend
          const response = await axios.get(`${apiUrl}/api/verify-token`);

          if (response.data.valid) {
            const isAdmin = localStorage.getItem("isAdmin") === "true";
            const searchParams = new URLSearchParams(location.search);
            const redirectPath = searchParams.get("redirect");

            if (redirectPath) {
              navigate(redirectPath);
            } else if (isAdmin) {
              navigate("/admin/approve");
            } else {
              navigate("/main");
            }
          } else {
            // Clear invalid token
            localStorage.removeItem("authToken");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("loginTimestamp");
          }
        } catch (error) {
          // Clear invalid token
          localStorage.removeItem("authToken");
          localStorage.removeItem("isAdmin");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("loginTimestamp");
        }
      }
    };

    fetchConfig();
    if (apiUrl) {
      verifySession();
    }
  }, [navigate, location, apiUrl]); // Added apiUrl to dependency array

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true); // Add loading state
    console.log("Attempting login with:", { email, password: "****" });

    if (!apiUrl) {
      setError(
        "Server configuration is not available. Please refresh the page and try again."
      );
      setIsLoading(false); // Reset loading state
      return;
    }

    try {
      const loginUrl = `${apiUrl}/api/login`;
      console.log("Sending login request to:", loginUrl);
      const response = await axios.post(loginUrl, { email, password });
      console.log("Login response:", response.data);

      if (response.data && response.data.token) {
        console.log("Login successful");
        // Store authentication data
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("isAdmin", response.data.isAdmin);
        localStorage.setItem("userEmail", response.data.email);
        localStorage.setItem("loginTimestamp", new Date().toISOString());

        // Set axios default header for future requests
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;

        onLoginSuccess(response.data);

        const searchParams = new URLSearchParams(location.search);
        const redirectPath = searchParams.get("redirect");

        if (redirectPath) {
          navigate(redirectPath);
        } else if (response.data.isAdmin) {
          navigate("/admin-dashboard");
        } else {
          navigate("/main");
        }
      } else {
        console.error(
          "Login failed: Invalid response from server",
          response.data
        );
        setError("Login failed: Invalid response from server");
      }
    } catch (error) {
      console.error("Login error:", error);

      if (error.response) {
        console.error("Error response:", error.response.data);
        setError(
          error.response.data.message ||
            "Login failed. Please check your credentials."
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
        setError(
          "No response from server. Please check your internet connection and try again."
        );
      } else {
        console.error("Error", error.message);
        setError("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false); // Reset loading state whether request succeeds or fails
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (!apiUrl) {
      setError(
        "Server configuration is not available. Please refresh the page and try again."
      );
      return;
    }

    try {
      const forgotPasswordUrl = `${apiUrl}/api/forgot-password`;
      console.log("Sending forgot password request to:", forgotPasswordUrl);
      const response = await axios.post(forgotPasswordUrl, { email });
      setMessage("Password reset link has been sent to your email.");
    } catch (err) {
      console.error("Forgot password error:", err);
      setError(
        err.response?.data?.message ||
          "Error sending reset link. Please try again."
      );
    }
  };

  return (
    <LoginContainer>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Logo src={logo} alt="Keboon Logo" />
      </Link>
      <Subtitle>
        {showForgotPassword ? "Reset Password" : "Please Sign in"}
      </Subtitle>
      {error && <Error>{error}</Error>}
      {message && <Message>{message}</Message>}
      {!showForgotPassword ? (
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <ForgotPassword onClick={() => setShowForgotPassword(true)}>
            Forgot password?
          </ForgotPassword>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Signing In..." : "Sign In"}
          </Button>
        </Form>
      ) : (
        <Form onSubmit={handleForgotPassword}>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputWrapper>
          <Button type="submit">Send Reset Link</Button>
          <ForgotPassword onClick={() => setShowForgotPassword(false)}>
            Back to Login
          </ForgotPassword>
        </Form>
      )}
      <SignUpLink>
        Don't have an account?{" "}
        <Link to={`/signup${location.search}`} style={{ color: "purple" }}>
          Sign up
        </Link>
      </SignUpLink>
    </LoginContainer>
  );
}

export default LoginForm;
